import ModalSelectStore from 'components/ModalSelectStore';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useStores } from 'stores';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import ModalBase from 'components/ModalBase';
import { InputField } from 'components/Inputs/InputField';
import { DateTimeField } from 'components/Inputs/DateTimeField';
import { SearchBar } from 'components/SearchBar';
import { useWindowSize } from 'hooks/useWindowSize';
import { ExportButton } from 'components/Buttons/ExportButton';
import { XL_WIDTH } from 'constants/screenResolution';

// To add ("Оценочное расстояние (метры)", "Оценочное время (мин)") into report document just the field "order_estimate" into "exportFields"
const exportFields = (a: string[] = []) => [
  'id',
  'status',
  'cuser_id',
  'employee_name',
  'employee_phone',
  'code',
  ...a,
  'policy',
  'class',
  'city',
  'source_address',
  'destination_address',
  'week_day',
  'creation_date',
  'creation_time',
  'scheduled_date',
  'scheduled_time',
  'start_waiting_date',
  'start_waiting_time',
  'start_date',
  'start_time',
  'finish_date',
  'finish_time',
  'paid_waiting_time_s',
  'driver_name',
  'driver_phone',
  'driver_car',
  'driver_car_number',
  'distance',
  'cost_customer',
  'cost_customer_with_vat',
  'platform',
  'cuser_name',
  'cost_centers',
  'cancelled_time',
  'full_time',
  'coordinator',
  'cost_detail',
];

const Filter = observer((): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const { ordersStore, employeesStore, usersStore } = useStores();
  const [filter, setFilter] = useState<any>({ ...(ordersStore?.filter || {}) });
  const [dateRange, setDateRange] = useState<any[]>([]);
  const { t } = useTranslation('reports');
  const { t: tStatus } = useTranslation('statuses');
  const windowSize = useWindowSize();

  const employeeId = useMemo(() => ({ id: filter['$nodx_or']?.employee_id }), [filter['$nodx_or']?.employee_id]);
  const coordId = useMemo(
    () => ({ id: filter?.personal_coordinator?.coordinator_id }),
    [filter?.personal_coordinator?.coordinator_id],
  );
  const statusId = useMemo(() => ({ id: filter.employee_id }), [filter.status_id]);
  const classId = useMemo(() => ({ id: filter.employee_id }), [filter.class_id]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'visible';
  }, [visible]);

  const changeFilter = useCallback(
    (name: string, value: any, shouldCallOnFilterChangeProp: boolean = false) => {
      const newFilter = { ...filter };
      if (value) {
        if (name !== 'employee_id') {
          newFilter[name] = value;
        }
        if (name === 'employee_id') {
          if (ordersStore?.linkedList && ordersStore.linkedList['employee_id_id']) {
            const [current] = ordersStore.linkedList['employee_id_id'].filter((item: any) => item.id === value);
            if (current?.user_id) {
              newFilter['$nodx_or'] = {
                cuser_id: current?.user_id,
                employee_id: value,
              };
            } else {
              newFilter['$nodx_or'] = undefined;
            }
          } else {
            newFilter['$nodx_or'] = undefined;
          }
        } else if (name === 'department_id' || name === 'department_code') {
          newFilter['employee_id'] = {
            ...(newFilter['employee_id'] || {}),
            [name]: value,
          };
          newFilter[name] = undefined;
        }
        if (name === 'coordinator_id') {
          newFilter['personal_coordinator'] = {
            [name]: value,
          };
          newFilter[name] = undefined;
        }
      } else {
        if (name === 'department_id') {
          newFilter['employee_id'] = undefined;
        }
        if (name === 'coordinator_id') {
          newFilter['personal_coordinator'] = {
            [name]: undefined,
          };
        }
        if (name === 'employee_id') {
          newFilter['$nodx_or'] = undefined;
        } else {
          newFilter[name] = undefined;
        }
      }
      // fetch list on every value change if true (false if filter is modal to prevent list loading while filter is open)
      if (shouldCallOnFilterChangeProp) {
        ordersStore.setFilter(false, newFilter, 'id desc', ordersStore?.page, ordersStore?.limit || 10, true, false);
      }
      setFilter(newFilter);
    },
    [filter],
  );

  const onApply = useCallback((): void => {
    setVisible(false);
    ordersStore.setFilter(false, filter, 'id desc', ordersStore?.page, ordersStore?.limit || 10, true, false);
  }, [filter]);

  /**
   * @param ev
   * @param shouldCallOnFilterChangeProp fetch list on every value change if true
   * @returns
   */
  const handleSelectRange = (value, shouldCallOnFilterChangeProp: boolean = false): void => {
    if (!value || value.filter((v) => !!v).length === 0) {
      setDateRange([]);
      changeFilter('local_datetime', undefined, shouldCallOnFilterChangeProp);
      return;
    }
    const [dateStart, dateEnd] = value;
    const newDateRange = [new Date(dateStart), ...(dateEnd ? [new Date(dateEnd)] : [])];
    setDateRange(newDateRange);
    const startDate = moment(dateStart);
    const endDate = dateEnd ? moment(dateEnd) : moment();
    changeFilter(
      'local_datetime',
      ['between', startDate.format('YYYY-MM-DD 00:00:00'), endDate.format('YYYY-MM-DD 23:59:59')],
      shouldCallOnFilterChangeProp,
    );
  };

  const onModalClose = (): void => {
    const newFilter = ordersStore?.filter || {};
    setVisible(false);
    setFilter(newFilter);
    if (newFilter?.local_datetime?.length === 3) {
      setDateRange([newFilter.local_datetime[1], newFilter.local_datetime[2]]);
    } else {
      setDateRange([]);
    }
  };
  const fields = exportFields(['department_code', 'department_name']);
  return (
    <div className="md:ml-auto filter-container">
      {(windowSize.width || 0) <= XL_WIDTH ? (
        <ExportButton
          className="flex items-center lg:px-4 lg:w-auto"
          fields={fields}
          store={ordersStore}
          filter={filter}
        />
      ) : null}
      {(windowSize.width || 0) <= XL_WIDTH ? (
        <div>
          <button
            className="btn btn-light px-1.5 w-10 xl:px-4 xl:w-auto"
            type="button"
            onClick={() => setVisible(true)} /*@click="isOpen = true; document.body.classList.add('overflow-hidden')"*/
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M18.22 2.6c-1.14-.44-4.4-.76-8.22-.76-3.83 0-7.08.32-8.22.75-.24.08-.46.22-.63.4a.68.68 0 0 0 0 .86l6.2 8.22v3.45a1.52 1.52 0 0 0 .84 1.36L10.44 18a1.52 1.52 0 0 0 2.2-1.36v-4.57l6.2-8.22a.68.68 0 0 0 0-.86 1.42 1.42 0 0 0-.62-.4Zm-6.53 8.98c-.06.09-.1.2-.1.31v4.75a.46.46 0 0 1-.67.41l-2.25-1.12a.46.46 0 0 1-.26-.41v-3.63a.53.53 0 0 0-.1-.31L3.03 4.56c2.3.33 4.63.48 6.96.45 2.33.03 4.65-.12 6.96-.45l-5.27 7.02ZM10 3.95c-2.44.05-4.87-.12-7.28-.53 2.4-.4 4.84-.58 7.28-.52 2.44-.06 4.87.12 7.28.52-2.4.4-4.84.58-7.28.53Z"></path>
            </svg>
          </button>
          <ModalBase
            title={t('filter.title')}
            onClose={onModalClose}
            parentClassName="panel"
            visible={visible}
            contentAnimationShowClass="bottom-slide-in"
            contentAnimationHideClass="bottom-slide-out"
            contentClass="overflow-visible"
          >
            <form className="md:w-[30.25rem] mx-auto">
              <div className="mb-6">
                <div className="mb-4">
                  <InputField
                    onReset={() => changeFilter('id', '')}
                    value={filter?.id || ''}
                    onChange={(e) => changeFilter('id', e.target.value)}
                    containerClass="w-full"
                    placeholder={t('filter.id')}
                  />
                </div>
                <div className="mb-4">
                  <DateTimeField
                    onChange={(ev) => handleSelectRange(ev, false)}
                    dateFormat="DD.MM.YYYY"
                    selectsRange
                    startDate={dateRange && dateRange[0]}
                    endDate={dateRange && dateRange[1]}
                    placeholderText={t('filter.date')}
                  />
                </div>
                <div className="mb-4">
                  <ModalSelectStore
                    store={ordersStore}
                    fieldName={'employee_id'}
                    placeholder={t('filter.full_name')}
                    filter={employeeId}
                    omitValuesOnFocus={['id']}
                    value={filter['$nodx_or']?.employee_id || ''}
                    onChange={(id: number) => changeFilter('employee_id', id)}
                  />
                </div>
                {usersStore?.isAdmin || usersStore?.isCustomer ? (
                  <div className="mb-4">
                    <ModalSelectStore
                      store={employeesStore}
                      fieldName={'coordinator_id'}
                      placeholder={t('filter.coordinator_id')}
                      filter={coordId}
                      omitValuesOnFocus={['id']}
                      value={filter?.personal_coordinator?.coordinator_id || ''}
                      onChange={(id: number) => changeFilter('coordinator_id', id)}
                    />
                  </div>
                ) : null}
                <div className="mb-4">
                  <SearchBar
                    containerClass="w-full"
                    onTextChange={(text: string) => changeFilter('employee_name', ['like', text || ''])}
                    preventDefaultSearch
                    onReset={() => changeFilter('employee_name', ['like', ''])}
                    store={ordersStore}
                    initValue={filter['employee_name'] ? filter['employee_name'][1] : ''}
                    placeholder={t('filter.search_placeholder')}
                  />
                </div>
                <div className="mb-4">
                  <ModalSelectStore
                    store={ordersStore}
                    fieldName={'status_id'}
                    placeholder={t('filter.status')}
                    filter={statusId}
                    omitValuesOnFocus={['id']}
                    value={filter.status_id}
                    translateOption={(k) => tStatus(`${k?.id}`)}
                    onChange={(id: number) => changeFilter('status_id', id)}
                  />
                </div>
                <div className="mb-4">
                  <ModalSelectStore
                    store={ordersStore}
                    fieldName={'class_id'}
                    placeholder={t('filter.class')}
                    filter={classId}
                    omitValuesOnFocus={['id']}
                    value={filter.class_id}
                    onChange={(id: number) => changeFilter('class_id', id)}
                  />
                </div>
                <div className="mb-4">
                  {usersStore?.isDepartmentsV2 ? (
                    <ModalSelectStore
                      store={ordersStore}
                      fieldName={'department_id'}
                      placeholder={t('filter.department_id')}
                      omitValuesOnFocus={['id']}
                      value={filter?.employee_id?.department_id}
                      onChange={(id: number) => changeFilter('department_id', id)}
                      nested
                    />
                  ) : (
                    <InputField
                      onReset={() => changeFilter('department_code', '')}
                      value={filter?.employee_id?.department_code || filter?.department_code || ''}
                      onChange={(e) => changeFilter('department_code', e.target.value)}
                      containerClass="w-full"
                      placeholder={t('filter.department_code')}
                    />
                  )}
                </div>
              </div>
              <div className="flex justify-end space-x-5">
                <button className="btn btn-blue w-full" type="button" onClick={onApply}>
                  {t('filter.actions.apply')}
                </button>
              </div>
            </form>
          </ModalBase>
        </div>
      ) : (
        <>
          <div className="filter-container__item">
            <InputField
              onReset={() => changeFilter('id', '', true)}
              value={filter?.id || ''}
              onChange={(e) => changeFilter('id', e.target.value, true)}
              containerClass="w-full"
              placeholder={t('filter.id')}
            />
          </div>
          <div className="filter-container__item">
            <SearchBar
              containerClass="w-full"
              onTextChange={(text: string) => changeFilter('employee_name', ['like', text || ''], true)}
              preventDefaultSearch
              onReset={() => changeFilter('employee_name', ['like', ''], true)}
              store={ordersStore}
              initValue={filter['employee_name'] ? filter['employee_name'][1] : ''}
              placeholder={t('filter.search_placeholder')}
            />
          </div>
          <div className="filter-container__item">
            {usersStore?.isDepartmentsV2 ? (
              <ModalSelectStore
                store={ordersStore}
                fieldName={'department_id'}
                placeholder={t('filter.department_id')}
                omitValuesOnFocus={['id']}
                value={filter?.employee_id?.department_id}
                onChange={(id: number) => changeFilter('department_id', id, true)}
                nested
              />
            ) : (
              <InputField
                onReset={() => changeFilter('department_code', '', true)}
                value={filter?.employee_id?.department_code || ''}
                onChange={(e) => changeFilter('department_code', e.target.value, true)}
                containerClass="w-full"
                placeholder={t('filter.department_code')}
              />
            )}
          </div>
          <div className="filter-container__item filter-container__item_selectable">
            <DateTimeField
              onChange={(ev) => handleSelectRange(ev, true)}
              dateFormat="DD.MM.YYYY"
              selectsRange
              startDate={dateRange && dateRange[0]}
              endDate={dateRange && dateRange[1]}
              placeholderText={t('filter.date')}
            />
          </div>
          <div className="filter-container__item filter-container__item_selectable">
            <ModalSelectStore
              store={ordersStore}
              fieldName={'employee_id'}
              placeholder={t('filter.full_name')}
              filter={employeeId}
              omitValuesOnFocus={['id']}
              value={filter['$nodx_or']?.employee_id || ''}
              onChange={(id: number) => changeFilter('employee_id', id, true)}
            />
          </div>
          {usersStore?.isAdmin || usersStore?.isCustomer ? (
            <div className="filter-container__item filter-container__item_selectable">
              <ModalSelectStore
                store={employeesStore}
                fieldName={'coordinator_id'}
                placeholder={t('filter.coordinator_id')}
                filter={coordId}
                omitValuesOnFocus={['id']}
                value={filter?.personal_coordinator?.coordinator_id || ''}
                onChange={(id: number) => changeFilter('coordinator_id', id, true)}
              />
            </div>
          ) : null}
          <div className="filter-container__item filter-container__item_selectable">
            <ModalSelectStore
              store={ordersStore}
              fieldName={'status_id'}
              placeholder={t('filter.status')}
              filter={statusId}
              omitValuesOnFocus={['id']}
              value={filter.status_id}
              translateOption={(k) => tStatus(`${k?.id}`)}
              onChange={(id: number) => changeFilter('status_id', id, true)}
            />
          </div>
          <div className="filter-container__item filter-container__item_selectable">
            <ModalSelectStore
              store={ordersStore}
              fieldName={'class_id'}
              placeholder={t('filter.class')}
              filter={classId}
              omitValuesOnFocus={['id']}
              value={filter.class_id}
              onChange={(id: number) => changeFilter('class_id', id, true)}
            />
          </div>
          {(windowSize.width || 0) > XL_WIDTH ? (
            <div className="filter-container__item filter-container__button-export">
              <ExportButton
                className="space-x-1.5 md:px-4 md:w-auto"
                fields={fields}
                store={ordersStore}
                filter={filter}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
});

export default Filter;
