import React, { useRef, useEffect, useState, useCallback, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { omit, isEqual, omitBy, isUndefined } from 'lodash';
import { useClickedOutside } from 'hooks/useCkickedOutside';
import { Employee } from '../../types/types';
import { Checkbox } from 'components/Inputs/Checkbox';
import { ReactComponent as ExpandIcon } from '../../assets/svg/plus.svg';
import { ReactComponent as CollapseIcon } from '../../assets/svg/minus.svg';
export interface ModalSelectStoreProps {
  mode?: 'select' | 'dropdown';
  style?: React.CSSProperties;
  omitValuesOnFocus?: string[];
  children?: React.ReactChild | React.ReactChild[];
  onChange?: any;
  onFocus?: any;
  inputOnly?: boolean;
  value?: any;
  defaultValue?: any;
  store?: any;
  fieldName?: any;
  placeholder?: string;
  filter?: any;
  wrapperClass?: string;
  innerClass?: string;
  inputWrapperClass?: string;
  containerClass?: string;
  inputClass?: string;
  optionClass?: string;
  idPrefix?: string;
  isSetDefaultValueIfLengthEq1?: boolean;
  onSetDefaultValueIfLengthEq1?: () => void;
  listProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;
  withPointer?: boolean;
  pointerPositionX?: 'right' | 'left';
  pointerPositionY?: 'bottom' | 'top';
  closeOnBackdropPress?: boolean;
  fields?: Array<string>;
  onOpen?: any;
  defaultOptions?: { id?: any; name?: string; title?: string; [key: string]: any }[];
  orderBy?: string;
  onClose?: any;
  alwaysBottom?: boolean;
  optionsWithId?: boolean;
  customOptionRender?: (option: any, index: number, arr: any[], isDefaultOption: boolean) => ReactNode;
  customUid?: string;
  disabled?: boolean;
  multi?: boolean;
  nested?: boolean;
  straight?: boolean;
  keyValue?: string;
  limit?: number;
  onInitialDataLoaded?: (data: any) => any;
  translateOption?: (data: any) => string;
}

const defaultStyles = {
  height: 0,
  overflow: 'scroll',
  'border-width': 0,
};

const ModalSelectStore = observer(
  ({
    mode = 'select',
    value = '',
    defaultValue = '',
    store,
    fieldName,
    filter,
    placeholder = '',
    onChange,
    idPrefix,
    listProps = {},
    withPointer,
    pointerPositionX = 'right',
    inputWrapperClass = '',
    inputClass = '',
    wrapperClass = '',
    innerClass = '',
    onClose,
    optionClass = '',
    onOpen,
    containerClass = '',
    closeOnBackdropPress = true,
    alwaysBottom = false,
    pointerPositionY = 'bottom',
    inputOnly = false,
    fields = [],
    defaultOptions = [],
    isSetDefaultValueIfLengthEq1 = false,
    orderBy = 'id desc',
    omitValuesOnFocus,
    optionsWithId = false,
    customOptionRender,
    customUid,
    disabled = false,
    multi = false,
    nested = false,
    onInitialDataLoaded,
    onSetDefaultValueIfLengthEq1,
    limit = 10,
    straight = false,
    keyValue = 'id',
    translateOption,
    ...rest
  }: ModalSelectStoreProps): JSX.Element => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const [styles, setStyles] = useState<any>(defaultStyles);
    const [filterState, setFilterState] = useState<any>({});
    const [visible, setVisible] = useState<boolean>(false);
    const [parent, setParent] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [isDefaultSelected, setIsDefaultSelected] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [onlyOneDefaultValue, setOnlyOneDefaultValue] = useState<Employee | null | undefined>(undefined);
    const isInitialDataLoaded = useRef<boolean>(false);

    const field: any = inputOnly
      ? ''
      : fieldName
        ? fieldName === 'role_id'
          ? {
              name: 'role_id',
              type: 'number',
              max: 11,
              link: {
                model: 'RolesModel',
              },
            }
          : store.specification.fields.find((field: any) => field?.name === fieldName)
        : undefined;
    const uid: string = inputOnly ? '' : customUid ? customUid : field ? field?.name + '_id' : '';

    useEffect(() => {
      if (visible) {
        setTimeout(() => setStyles(getPosition()));
        if (onOpen) {
          onOpen();
        }
      } else {
        setStyles(defaultStyles);
        if (onClose) {
          onClose();
        }
      }
    }, [visible]);

    useEffect(() => {
      if (inputRef.current) inputRef.current.focus();
    }, [inputRef.current]);

    const loadData = async (text: string = '', params: any = {}, onFocus: boolean = false): Promise<void> => {
      if (!isEqual(omitBy(filterState, isUndefined), omitBy(params, isUndefined)) || search !== text || onFocus) {
        setFilterState(params);
        setSearch(text);
        if (straight) {
          store.fetchList(false, { $nodx_search: text, ...params }, orderBy).then((data) => {
            if (!isInitialDataLoaded.current && onInitialDataLoaded) {
              onInitialDataLoaded(data);
            }
            isInitialDataLoaded.current = true;
          });
        } else {
          store
            .fetchLinkedList(field?.name, { $nodx_search: text, ...params }, fields, orderBy, uid, nested, limit)
            .then((data) => {
              if (!isInitialDataLoaded.current && onInitialDataLoaded) {
                onInitialDataLoaded(data);
              }
              isInitialDataLoaded.current = true;
            });
        }
      }
    };

    useEffect(() => {
      (async () => {
        if (!isInitialDataLoaded.current) {
          await store.clearLists(uid);
        }
        if (!isInitialDataLoaded.current && value) {
          await loadData('', { ...filter, id: value }, true);
        }
      })();
    }, [value, isInitialDataLoaded.current]);

    useEffect(() => {
      const list = straight ? store.list : store.linkedList && store.linkedList[uid];
      if (
        (!filter || (!filter[keyValue] && !filter['$nodx_search'])) &&
        list?.length === 1 &&
        isSetDefaultValueIfLengthEq1 &&
        onlyOneDefaultValue === undefined &&
        !defaultValue
      ) {
        onSetDefaultValueIfLengthEq1 && onSetDefaultValueIfLengthEq1();
        setOnlyOneDefaultValue(list[0]);
        const option = list[0];
        setIsDefaultSelected(true);
        inputOnly = true;
        if (multi) {
          const values = (value && value[0] === 'in' && value[1]) || value;
          const newValue =
            values.indexOf(option[keyValue]) >= 0
              ? values.filter((v) => v != option[keyValue])
              : [...values, option[keyValue]];
          onChange(['in', newValue], option);
        } else {
          onChange(option[keyValue], option);
        }
      } else if (isSetDefaultValueIfLengthEq1 && onlyOneDefaultValue === undefined) {
        // store.clearLists(uid);
        setOnlyOneDefaultValue(null);
      }
    }, [filter, store.linkedList, straight, store.list]);

    useClickedOutside(wrapperRef?.current, () => {
      if (closeOnBackdropPress && visible) {
        setVisible(false);
        loadData('', filter);
      }
    });

    const getPosition = (): React.CSSProperties => {
      let position: any = {};
      const wrapperRect: DOMRect | undefined = wrapperRef.current?.getBoundingClientRect();
      const innerRect: DOMRect | undefined = innerRef.current?.getBoundingClientRect();
      if (!wrapperRef.current || !window || !wrapperRect || !innerRect) return position;
      if (withPointer) {
        position.top = 'calc(100% + 5px)';
        position.right = -10;
      }
      if (
        (!alwaysBottom &&
          window.innerHeight - wrapperRect.bottom < innerRect?.height + 10 &&
          window.innerHeight - wrapperRect.bottom < innerRect.top) ||
        pointerPositionY === 'top'
      ) {
        position = {
          ...position,
          top: 'auto',
          bottom: withPointer ? +wrapperRect.height + 5 : +wrapperRect.height + 5,
          height: 'auto',
        };
      }
      if (pointerPositionX === 'left') {
        position = {
          ...position,
          right: 'auto',
          left: -10,
        };
      }
      return position;
    };

    const onOptionClick = (e: any, option: any, isDefaultOption: boolean = false): void => {
      e.stopPropagation();
      setVisible(false);
      if (onChange) {
        if (multi) {
          const values = (value && value[0] === 'in' && value[1]) || value;
          const newValue =
            values.indexOf(option[keyValue]) >= 0
              ? values.filter((v) => v != option[keyValue])
              : [...values, option[keyValue]];
          onChange(['in', newValue], option);
        } else {
          onChange(option[keyValue], option);
        }
      }
      setIsDefaultSelected(isDefaultOption);
    };

    const renderMultiOptionContent = (option: any): React.ReactElement => {
      const values = (value && value[0] === 'in' && value[1]) || value;
      const [checked] = multi && values && values.filter((v) => v == option[keyValue]);
      return (
        <Checkbox
          containerProps={{ onClick: (e) => e.stopPropagation() }}
          checked={!!checked}
          label={translateOption ? translateOption(option) : option.title || option.name}
          containerClass="flex items-center"
          labelClass="w-full"
          onChange={({ target }) => {
            const newValue =
              values.indexOf(option[keyValue]) >= 0
                ? values.filter((v) => v != option[keyValue])
                : [...values, option[keyValue]];
            onChange(['in', newValue], option);
          }}
        />
      );
    };

    const sortOption = (a: any) => {
      if (multi) {
        const values = (value && value[0] === 'in' && value[1]) || value;
        const [checked] = multi && values && values.filter((v) => v == a[keyValue]);
        return checked ? -1 : 1;
      } else {
        return 1;
      }
    };

    const onExpand = (e, parent: any, isParent: boolean) => {
      e.stopPropagation();
      e.preventDefault();
      setSearch('');
      if (isParent) {
        setParent(isParent ? null : parent);
        loadData('', { ...filterState, parent_id: null });
      } else {
        setParent(isParent ? null : parent);
        loadData('', { ...filterState, parent_id: parent[keyValue] });
      }
    };
    const renderOption = (
      option: any,
      index: number,
      arr: any[],
      isDefaultOption: boolean = false,
      isParent: boolean = false,
    ): React.ReactElement => {
      let attrs: any = {
        role: 'option',
        className: `${mode}-item ${optionClass}`,
        onClick: (e) => onOptionClick(e, option, isDefaultOption),
        key: option[keyValue],
      };
      attrs = idPrefix ? { ...attrs, id: `${idPrefix}-${index}` } : attrs;
      console.log('renderOption!', customOptionRender, optionsWithId, multi, option.name);
      return (
        <li {...attrs} style={{ paddingLeft: nested ? `${((option.level || 1) - 1) * 20 + 10}px` : '10px' }}>
          {customOptionRender
            ? customOptionRender(option, index, arr, isDefaultOption)
            : optionsWithId
              ? `[${option[keyValue]}] ${option.title || option.name}`
              : multi
                ? renderMultiOptionContent(option)
                : translateOption
                  ? translateOption(option)
                  : option.title || option.name}
        </li>
      );
    };

    useEffect(() => {
      if (defaultOptions?.length > 0 && value) {
        defaultOptions.forEach((o) => {
          if (o[keyValue] === value) {
            setIsDefaultSelected(true);
          }
        });
      }
    }, [value, defaultOptions]);

    const onInputChange = (e: any): void => {
      const text = e?.target?.value || '';
      if (inputOnly && onChange) {
        onChange(text);
      }
      loadData(text, filterState);
    };

    const reset = (e: any): void => {
      setIsDefaultSelected(false);
      clearData(e);
      if (onChange && value) {
        onChange(undefined);
      }
    };

    const omitFilterValuesOnFocus = useCallback((): any => {
      if (!omitValuesOnFocus?.length) return filterState;
      let newFilter = omit(filter, omitValuesOnFocus);
      setFilterState(newFilter);
      return newFilter;
    }, [filterState]);

    const clearData = (e: any): void => {
      if (e) e?.stopPropagation();
      omitFilterValuesOnFocus();
      setSearch('');
    };

    const onInputFocus = (): void => {
      if (rest.onFocus) rest.onFocus();
      loadData('', omitFilterValuesOnFocus(), true);
    };

    const onClickField = (e): void => {
      if (!inputOnly) setVisible(!visible);
      clearData(e);
    };

    const list = straight ? store.list : store.linkedList && store.linkedList[uid];

    const renderValue = () => {
      const defaultValue = defaultOptions.find((item: any) => item[keyValue] === value);
      const chosenValue = list && list.find((item: any) => item[keyValue] == value);
      const values = (value && value[0] === 'in' && value[1]) || value;
      const multiChosenValues = multi && list ? list.filter((item: any) => values.indexOf(item[keyValue]) >= 0) : [];
      return (
        <>
          {isDefaultSelected ? (
            <span className="text-black">{defaultValue?.title || defaultValue?.name}</span>
          ) : value ? (
            <span className="text-black">
              {!multi && (chosenValue?.title || chosenValue?.name || '')}
              {multi && multiChosenValues.map((i) => i.title || i.name).join(', ')}
            </span>
          ) : (
            <span className="text-black text-opacity-40">{placeholder}</span>
          )}
        </>
      );
    };
    return (
      <div ref={wrapperRef} className={`${mode} ${wrapperClass}`}>
        <div
          className={`select-toggler items-center flex ${visible ? 'is-open' : ''} ${inputOnly ? 'input-search' : ''} ${inputWrapperClass}`}
          aria-haspopup="listbox"
          aria-labelledby="role-label"
        >
          <div className="flex w-full justify-between" onClick={onClickField}>
            {visible || inputOnly || onlyOneDefaultValue ? (
              <input
                ref={inputRef}
                onChange={onInputChange}
                onFocus={onInputFocus}
                value={onlyOneDefaultValue?.name || search}
                className={`input-filter-field ${inputClass}`}
                onClick={(e) => e.stopPropagation()}
                placeholder={placeholder}
                readOnly={!!onlyOneDefaultValue}
                {...rest}
              />
            ) : (
              <div className="many-lines-text" style={{ maxWidth: '300px' }}>
                {renderValue()}
                <input className="w-full zero-height-input block" disabled type="text"></input>
              </div>
            )}

            {!onlyOneDefaultValue && (
              <button onClick={reset} className=" text-black text-opacity-40 hover:text-opacity-50" type="button">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            )}
            {!onlyOneDefaultValue && !inputOnly && (
              <svg className="caret w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="m12.75 16.5 7.35-7.48c.4-.44.4-1.1 0-1.51a1.05 1.05 0 0 0-1.49 0L12 14.24 5.39 7.51a1.05 1.05 0 0 0-1.49 0 1.1 1.1 0 0 0 0 1.5l7.37 7.48c.4.41 1.06.41 1.48 0Z"></path>
              </svg>
            )}
          </div>
          {disabled ? <div className="disabling-overlay"></div> : null}
        </div>
        {!inputOnly && visible && (
          <div className={`${mode}-list ${containerClass}`} style={styles}>
            {withPointer && (
              <div
                className="pointer"
                style={{
                  transform: `rotate(${styles.bottom ? 225 : 45}deg)`,
                  right: pointerPositionX === 'right' ? 12 : 'auto',
                  left: pointerPositionX === 'left' ? 12 : 'auto',
                  top: styles.bottom ? (innerRef.current?.getBoundingClientRect().height || 0) - 7 : -8,
                }}
              />
            )}
            <div ref={innerRef}>
              {((list && list.length) || defaultOptions?.length) > 0 ? (
                <ul className={`${mode}-inner ${innerClass}`} role="listbox" {...listProps}>
                  {defaultOptions
                    ?.sort(sortOption)
                    .slice()
                    .map((item: any, i: number, arr: any[]) => renderOption(item, i, arr, true))}
                  {list && list.length > 0 ? list.slice().sort(sortOption).map(renderOption) : null}
                </ul>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default ModalSelectStore;
