export default {
  title: 'Департаменты',
  action: {
    add: 'Добавить департамент',
    edit: 'Редактирование департамента',
  },
  search_placeholder: 'Поиск',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  thead: {
    full_name: 'ФИО',
    email: 'E-mail',
  },
  dialog: {
    name: 'Название департамента',
    parent_id: 'Департамент верхнего уровня',
    error: 'Ошибка сохранения департамента, попробуйте ещё раз',
    email: 'E-mail',
    add: 'Добавить',
    save: 'Cохранить',
    cancel: 'Отменить',
  },
};
