import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { Switcher } from 'components/Switcher';
import { useTranslation } from 'react-i18next';
import declension from '../../utils/declension';
import { TariffCard } from '../../components/TariffCard';
import { TimeRestriction } from '../../components/TimeRestriction';
import FASpinner from '../../components/FASpinner';
import { Employee } from './dialog';

export interface TimeRestrictions {
  type: string;
  title: string;
  days?: string[];
  minutes: {
    start: number;
    end: number;
  };
  start_time?: number;
  end_time?: number;
  max_order_count?: number | null;
}
interface CostRestrictions {
  type: string;
  total_cost_inc_tax: number;
}
interface LimitProps {
  title: string;
  classes: string[];
  time_restrictions: TimeRestrictions[];
  cost_restriction: CostRestrictions;
  is_enabled_cost_restriction: boolean;
  is_enabled_class_restriction: boolean;
  is_enabled_time_restrictions: boolean;
  cuser_id?: number;
  muser_id?: number;
}
interface LimitsProps {
  employeeId: number;
  onCancel: any;
  onClose: any;
}

const DEFAULT_LIMITS: LimitProps = {
  title: '',
  classes: [],
  time_restrictions: [],
  cost_restriction: {
    type: 'month',
    total_cost_inc_tax: 0,
  },
  is_enabled_cost_restriction: false,
  is_enabled_time_restrictions: false,
  is_enabled_class_restriction: false,
};

const sortRestrictions = (a, b) => {
  const diff = RESTRICTION_ORDER.indexOf(a.type) - RESTRICTION_ORDER.indexOf(b.type);
  if (diff !== 0) return diff;
  if (a.type === 'interval') {
    return (a.start_time || Infinity) - (b.start_time || 0);
  }
  return 0;
};

const RESTRICTION_ORDER = ['interval', 'days', 'weekdays', 'weekends'];
export const LimitTab = observer(({ onCancel, onClose, employeeId }: LimitsProps): JSX.Element | null => {
  const { usersStore, employeesStore, ordersClassesStore, limitsStore } = useStores();
  const { t } = useTranslation(['employees', 'validation']);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);

  const [limitState, setLimitState] = useState<LimitProps>(DEFAULT_LIMITS);

  const fetchData = async () => {
    const data = await limitsStore.getLimit(employeeId);
    console.log('data -->', data);
    if (data) {
      setLimitState({
        ...data,
        ...(data.time_restrictions
          ? {
              time_restrictions: data.time_restrictions.map((r) => ({
                ...r,
                ...(r.type === 'days' && r.days
                  ? {
                      type:
                        r.days.join() === 'mo,tu,we,th,fr'
                          ? 'weekdays'
                          : r.days.join() === 'su,sa'
                            ? 'weekends'
                            : 'days',
                    }
                  : {}),
              })),
            }
          : {}),
      });
    }
  };

  useEffect(() => {
    fetchData();
    ordersClassesStore.fetchList(false, {}, 'id asc', 0, 20, true, false).then(() => setIsDataReady(true));
  }, []);
  const addTimeRestriction = () => {
    updateState('time_restrictions', [
      ...limitState.time_restrictions,
      {
        title: limitState.time_restrictions.length,
        type: 'interval',
        minutes: { start: 0, end: 1439 },
        start_time: 0,
        end_time: 0,
      },
    ]);
  };

  const updateTimeRestriction = (index: number, restriction: TimeRestrictions) => {
    const newRestrictions = [...limitState.time_restrictions];
    newRestrictions[index] = restriction;
    updateState('time_restrictions', newRestrictions);
  };

  const deleteTimeRestriction = (title: string, index: number) => {
    const newRestrictions = [...limitState.time_restrictions]
      .sort(sortRestrictions)
      .filter((t, i) => (title ? t.title !== title : i !== index));
    updateState('time_restrictions', newRestrictions);
  };

  const updateState = (key: string, value: any) => {
    setLimitState({
      ...limitState,
      [key]: value,
    });
  };

  const changeClasses = (className: string = '') => {
    updateState(
      'classes',
      limitState.classes.indexOf(className) >= 0
        ? limitState.classes.filter((c) => c !== className)
        : [...limitState.classes, className],
    );
  };

  const onSelectCostRestrictionType = (e) => {
    updateState('cost_restriction', {
      ...limitState.cost_restriction,
      type: e.target.value,
    });
  };

  const addEmployeeLimits = async () => {
    // alert(`текущее состояние: \r\n${JSON.stringify(limitState, null, 2)}`);
    try {
      await limitsStore.createLimit(employeeId, {
        ...limitState,
        time_restrictions: (limitState.time_restrictions || []).map((r) => ({
          ...r,
          type: r.type === 'weekdays' || r.type === 'weekends' ? 'days' : r.type,
        })),
      });
      await employeesStore.fetchList(
        false,
        employeesStore.filter,
        employeesStore.orderBy || 'id desc',
        employeesStore.page || 0,
        employeesStore?.limit || 10,
        true,
        false,
      );
      if (onClose) onClose();
    } catch (e) {}
  };

  const cancel = () => {
    setLimitState(DEFAULT_LIMITS);
    if (onCancel) {
      onCancel();
    }
  };
  const isDisabledSaveLimits = (): boolean => {
    if (
      limitState.time_restrictions &&
      limitState.time_restrictions.some((r) => r.type === 'interval' && (!r.end_time || !r.start_time))
    )
      return true;
    if (
      limitState.cost_restriction &&
      limitState.is_enabled_cost_restriction &&
      (!limitState.cost_restriction?.type || !limitState.cost_restriction?.total_cost_inc_tax)
    )
      return true;
    return false;
  };

  return (
    <div className="restrictions">
      <div className="flex-grow">
        <Switcher
          name={t('limits.cost_restriction')}
          value={limitState.is_enabled_cost_restriction}
          onChange={(newValue) => updateState('is_enabled_cost_restriction', newValue)}
        >
          <div className="flex mt-3">
            <div className="flex-1">
              <label className="form-label" htmlFor="cost">
                {t('limits.cost')}
              </label>
              <input
                className={`form-control py-3`}
                type="text"
                onChange={(e) =>
                  !isNaN(Number(e.target.value)) &&
                  updateState('cost_restriction', {
                    ...limitState.cost_restriction,
                    total_cost_inc_tax: Number(e.target.value),
                  })
                }
                id="cost"
                placeholder={t('limits.cost')}
                value={limitState?.cost_restriction?.total_cost_inc_tax || ''}
              />
            </div>
            <div className="flex-1 ml-3">
              <label className="form-label"> </label>
              <select
                className="form-control"
                value={limitState.cost_restriction?.type || ''}
                onChange={onSelectCostRestrictionType}
                style={(!limitState.cost_restriction?.type && { color: '#9ca3af' }) || {}}
              >
                <option value="" disabled>
                  {t('limits.period')}
                </option>
                {['month', 'day', 'week'].map((v) => (
                  <option key={`cost_type-${v}`} value={v}>
                    {t(`limits.cost_type.${v}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Switcher>
        <Switcher
          name={t('limits.time_restrictions')}
          description={
            limitState.is_enabled_time_restrictions && limitState.time_restrictions.length
              ? `${t('limits.chosen')} ${declension('limits.restrictions', limitState.time_restrictions.length, t)} `
              : ''
          }
          value={limitState.is_enabled_time_restrictions}
          onChange={(newValue) => updateState('is_enabled_time_restrictions', newValue)}
        >
          <div className="mt-3">
            {limitState.time_restrictions.sort(sortRestrictions).map((restriction, index) => (
              <TimeRestriction
                key={`${restriction.type}-${restriction.title}`}
                restriction={restriction}
                onChange={(updatedRestriction) => updateTimeRestriction(index, updatedRestriction)}
                onDelete={() => deleteTimeRestriction(restriction.title, index)}
                ind={index}
              />
            ))}
            <button className="btn time-restrictions__add" type="button" onClick={addTimeRestriction}>
              {t('limits.add')}
            </button>
          </div>
        </Switcher>
        <Switcher
          name={t('limits.class_restrictions')}
          description={
            limitState.is_enabled_class_restriction && limitState.classes.length
              ? `${t('limits.chosen')} ${declension('limits.restrictions', limitState.classes.length, t)} `
              : ''
          }
          value={limitState.is_enabled_class_restriction}
          onChange={(newValue) => updateState('is_enabled_class_restriction', newValue)}
        >
          <div className="overflow-x-auto mt-3">
            <div>
              {ordersClassesStore?.isFetchingListInProgress || !isDataReady ? (
                <span>{t('loading')}</span>
              ) : (
                <>
                  <div className="cars-list mb-4">
                    {ordersClassesStore?.list.map((cls: { name?: string; title?: string; icon512: string }) => (
                      <TariffCard
                        onClick={() => changeClasses(cls.name)}
                        active={limitState.classes.indexOf(cls.name || '') >= 0}
                        tariff={{ name: cls.name, title: cls.title, icon512: cls.icon512 }}
                      />
                    ))}
                  </div>
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="all-classes"
                      checked={
                        (limitState.classes || []).length > 0 &&
                        limitState.classes?.length === ordersClassesStore.list?.length
                      }
                      onChange={({ target }) =>
                        updateState(
                          'classes',
                          limitState.classes?.length === ordersClassesStore.list?.length
                            ? []
                            : ordersClassesStore.list?.map((o: any) => o.name),
                        )
                      }
                    />
                    <label className="text-black text-opacity-40 ml-2.5" htmlFor="all-classes">
                      {t('limits.classes_all')}
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        </Switcher>
      </div>
      <div className="flex justify-end space-x-5 mt-5">
        <button
          className="btn btn-blue"
          type="button"
          disabled={employeesStore?.isAddingInProgress || isDisabledSaveLimits()}
          onClick={addEmployeeLimits}
        >
          <FASpinner containerClass="mr-2" show={employeesStore?.isAddingInProgress} />
          {t('dialog.save')}
        </button>
      </div>
    </div>
  );
});
