export default {
  title: 'Promo Codes',
  loading: 'Loading data',
  error: 'Error',
  action: {
    add: 'Add Promo Code',
    edit: 'Edit',
    import: 'Import Promo Codes',
    export: 'Export',
    exporting: 'Exporting',
    resend_sms: 'Resend SMS',
    export_orders: 'Export Trips',
    activate: 'Activate Promo Code',
    disable: 'Disable',
    enable: 'Enable',
  },
  import: {
    imported_number: 'Records Imported',
    failed_number: 'Records Skipped',
    import_process: 'Import',
    from: 'from',
    close: 'Close',
    dropzone_text: 'Click or drag a file to this area',
    import_example: 'Import File Example',
    import_file_name: 'import',
    errors: 'Import Errors',
    line: 'Line',
    end: 'Import Completed',
    success: 'Successfully Imported',
    lines: 'lines',
    lines_s1: 'line',
    lines_s2: 'lines',
  },
  thead: {
    code: 'Code',
    activated_time: 'Activation Time',
    total_cost: 'Total Amount',
    prefix: 'Prefix',
    max_order_cost: 'Maximum Trip Cost',
    orders_count: 'Available Trips Count',
    max_employee_count: 'Maximum Users Count',
    discount_percent: 'Discount Percentage',
    used_time: 'Last Used Time',
    used_orders_count: 'Usage Count',
    activated_by_employee_id: 'Activated By',
    count_activated: 'Activation Count',
    role: 'Travel Policy',
    active_from: 'Available From',
    active_to: 'Available Until',
    classes: 'Classes',
  },
  trow: {
    activated: 'Activated',
    deactivated: 'Deactivated',
    used: 'Used',
    employee: 'Employee',
    paid_waiting: 'Paid Waiting',
    ride: 'Trip',
    full_time: 'Total Time',
    minutes_short: 'm',
    arrival_time: 'Arrival Time',
    guest: '(Passenger / Guest)',
  },
  dialog: {
    code: 'Code',
    activated_time: 'Activation Time',
    total_cost: 'Total Amount',
    prefix: 'Prefix',
    max_order_cost: 'Maximum Trip Cost',
    orders_count: 'Available Trips Count',
    max_employee_count: 'Maximum Users Count',
    discount_percent: 'Discount Percentage',
    description: 'Description',
    used_time: 'Last Used Time',
    used_orders_count: 'Usage Count',
    activated_by_employee_id: 'Activated By',
    count_activated: 'Activation Count',
    active_from: 'Available From',
    active_to: 'Available Until',
    type: 'Promo Code Type',
    discount: 'Discount',
    amount: 'Amount',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    classes: 'Classes',
    classes_all: 'Select All',
    add_class: 'Add Class',
    promocode: 'Promo Code',
    activate: 'Activate',
    payment_method: 'Payment Method',
    rides_policy: 'Travel Policy',
  },
  details: {
    id: 'ID',
    manager: 'Manager',
    status: 'Status',
    start_transporting_time: 'Start Time',
    finished_time: 'End Time',
    cancelled_time: 'Cancellation Time',
    order_time: 'Order Creation Time',
    time: 'Time',
    source: 'Departure',
    interim_destination: 'Stopover',
    destination: 'Destination',
    employee: 'Employee',
    phone: 'Phone',
    class: 'Class',
    car: 'Car',
    cost_customer: 'Cost Without VAT, RUB',
    cost_customer_with_vat: 'Cost With VAT, RUB',
    customer_name: 'Customer Name',
    provider: 'Provider',
    provider_order_id: 'Provider Order Number',
    comment: 'Your Instructions for the Driver',
    cancelled_by: 'Cancelled By',
    orderer: 'Ordered By',
    additional_fields: 'Additional Fields',
  },
  search_placeholder: 'Search',
  filter: {
    title: 'Filters',
    status: 'Status',
    code: 'Code',
    activated_by_employee_id: 'Activated By',
    actions: {
      apply: 'Apply',
    },
  },
};
