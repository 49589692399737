import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formatError } from 'utils/lib/formatError';
import ModalBase from 'components/ModalBase';
import { Coordinator } from 'modules/Coordinators';
import { TabSwitcher } from '../../components/TabSwitcher';
import { LimitTab } from './LimitTab';
import { DataTab } from './DataTab';

interface DialogProps {
  onClose: any;
  visible: boolean;
  onCancel: any;
  user?: Employee;
}
export interface IDepartment {
  id: string;
  name?: string;
  parent_id?: string;
}

export interface Employee {
  active?: string | number;
  phone: string;
  email: string;
  name: string;
  id?: number;
  role?: any;
  can_order?: boolean | number;
  can_order_others?: boolean | number;
  is_vip?: boolean | number;
  role_id: number | undefined;
  department_id?: string | number | null;
  department_name?: string | number | null;
  department_code?: string | number | null;
  department?: IDepartment;
  code?: string;
  update?: any;
  limit_month_amount?: string | number;
  send_welcome_sms?: number;
  coordinator_id?: Coordinator['id'] | null | undefined;
  active_from_time?: number | null;
  active_to_time?: number | null;
}

export const Dialog = observer(({ visible, onClose, onCancel, user }: DialogProps): JSX.Element | null => {
  const { employeesStore } = useStores();
  const { t } = useTranslation(['employees', 'validation']);
  const [activeTab, setActiveTab] = useState<string>('data');

  useEffect(() => {
    if (visible) {
      employeesStore.addingError = '';
      setActiveTab('data');
    }
  }, [visible, user?.id]);
  return (
    <ModalBase
      visible={visible}
      title={t(`action.${user ? 'edit' : 'add'}`)}
      onClose={onClose}
      contentAnimationShowClass="scaled-up overflow-visible"
    >
      {employeesStore?.addingError && <p className="error mb-4">{formatError(employeesStore?.addingError, t)}</p>}
      {visible && (
        <form>
          {user?.id && (
            <TabSwitcher
              tabs={[
                { name: t('dialog.tabs.data'), value: 'data' },
                { name: t('dialog.tabs.limits'), value: 'limits' },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === 'data' && <DataTab user={user} onClose={onClose} onCancel={onCancel} visible={visible} />}

          {activeTab === 'limits' && <LimitTab employeeId={Number(user?.id)} onCancel={onCancel} onClose={onClose} />}
        </form>
      )}
    </ModalBase>
  );
});
