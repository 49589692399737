export default {
  title: 'Departments',
  action: {
    add: 'Add department',
    edit: 'Editing',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full name',
    email: 'E-mail',
  },
  dialog: {
    name: 'Department name',
    parent_id: 'Upper level Department',
    error: 'Department error, try again',
    email: 'E-mail',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
  },
};
