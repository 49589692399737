import { useTranslation } from 'react-i18next';
import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import ModalSelect from 'components/ModalSelect';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { isEmpty, omit, xor } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { Option } from 'types/types';
import { formatError } from 'utils/lib/formatError';
import languageData from '../../i18n/langdata';
import { omitEmptyValues } from 'utils/lib/omitEmptyValues';
import { Limit } from './Limit';

interface SettingsProps {
  onClose: any;
  visible: boolean;
}

const defaultForm = {
  current_password: '',
  new_password: '',
  new_password2: '',
};

const Settings = observer(({ onClose, visible }: SettingsProps): JSX.Element => {
  const { usersStore } = useStores();
  const { i18n } = useTranslation();
  const { t } = useNestedTranslation(['nav.settings', 'validation', 'errors']);
  const [errors, setErrors] = useState<any>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [form, setForm] = useState<any>({ ...defaultForm });
  const [locale, setLocale] = useState<string | undefined>();

  const [shownPassword, setShownPassword] = useState<string[]>([]);

  const fetchSettings = async () => {
    const settings = await usersStore.fetchSettings();
    const { lang_id } = usersStore?.me || {};
    setForm({ ...settings, lang_id, ...defaultForm });
    if (lang_id) {
      const currentLocale = languageData.find((l: any) => lang_id === l.id)?.locale;
      if (currentLocale) setLocale(currentLocale);
    }
  };

  useEffect(() => {
    if (visible) fetchSettings();
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  useEffect(() => {
    if (usersStore?.fetchingSettingsError) {
      setErrors({ fetching: formatError(usersStore?.fetchingSettingsError, t) });
    }
  }, [usersStore?.fetchingSettingsError]);

  const closeForm = (e: any, checkLocale: boolean = true): void => {
    if (checkLocale) {
      const changedLocale = languageData.find((l: any) => form.lang_id === l.id)?.locale;
      if (locale !== changedLocale && changedLocale) {
        i18n.changeLanguage(locale);
      }
    }
    setForm({ ...defaultForm });
    setErrors({});
    onClose();
  };

  const handleFormChange = (field: string, value: any) => {
    if (field === 'lang_id') {
      const newLocale = languageData.find((l: any) => value === l.id)?.locale;
      if (newLocale) {
        i18n.changeLanguage(newLocale);
      }
    }
    setForm({ ...form, [field]: value });
  };

  const handleDialogSave = async () => {
    if (!checkIsValidForm()) return;
    setIsSaving(true);
    console.log(22222, JSON.stringify(omitEmptyValues(form)));
    await usersStore.saveSettings(omitEmptyValues(form));
    if (usersStore.savingSettingsError) {
      setErrors({ submit: t(`error.${usersStore?.savingSettingsError}`, { ns: 'validation' }) });
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    closeForm(null, false);
  };

  const checkField = (field: string) =>
    form[field] && form[field]?.trim()?.length < 6 ? { [field]: t('error.password', { ns: 'validation' }) } : {};

  const checkIsValidForm = (): boolean => {
    let newErrors = { ...checkField('current_password') };
    newErrors = { ...newErrors, ...checkField('new_password') };
    newErrors = { ...newErrors, ...checkField('new_password2') };
    let isValid = isEmpty(newErrors);
    if (!isValid) setErrors(newErrors);
    return isValid;
  };

  const onFocus = (field: string): void => setErrors(omit(errors, field));

  const isSaveDisabled = (): boolean =>
    (form.current_password || form.new_password || form.new_password2) &&
    !(form.current_password && form.new_password && form.new_password2);

  return (
    <ModalBase visible={visible} disabledClose={isSaving} title={t('title')} onClose={closeForm}>
      <form className="lg:w-auto mx-auto">
        <div className="mb-6 md:mb-8 lg:mb-6">
          {usersStore?.isEmployee && (
            <Limit
              className="mb-2 pb-5 flex text-black border-b border-b-graystroke"
              limits={{
                limit_month_amount: usersStore?.me?.detail?.limit_month_amount,
                limit_month_amount_used: usersStore.me?.detail?.limit_month_amount_used,
                limit_group_month_amount: usersStore?.me?.detail?.limit_group_month_amount,
                limit_group_month_amount_used: usersStore?.me?.detail?.limit_group_month_amount_used,
              }}
            />
          )}
          <div className="mb-5">
            <label className="form-label" htmlFor="language">
              {t('lang')}
            </label>
            <div className="select select--lg">
              <ModalSelect
                options={languageData.map((l) => ({ id: l.id, label: l.name, value: l.name }))}
                onChange={(opt: Option) => handleFormChange('lang_id', opt.id)}
                value={languageData.find((l: any) => form.lang_id === l.id)?.name}
              />
            </div>
          </div>
          {!usersStore?.isEmployee && (
            <>
              <hr className="border-t border-t-graystroke my-5" />
              {errors?.submit && <h2 className="validate-error-message mb-2">{errors.submit}</h2>}

              {errors?.fetching && <h2 className="validate-error-message mb-2">{errors.fetching}</h2>}
              <div className="mb-5">
                <label className="form-label" htmlFor="current-password">
                  {t('password.current')}
                </label>
                <div className="relative">
                  <input
                    className={`form-control form-control--lg ${errors.current_password ? 'validate-error' : ''}`}
                    onFocus={() => onFocus('current_password')}
                    onChange={({ target }) => handleFormChange('current_password', target.value)}
                    value={form.current_password}
                    type={shownPassword.includes('current-password') ? 'text' : 'password'}
                    placeholder={t('password.current_placeholder')}
                  />
                  <button className="absolute right-3 top-3 text-black text-opacity-40" type="button">
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      onClick={() => setShownPassword(xor(shownPassword, ['current-password']))}
                    >
                      {shownPassword.includes('current-password') ? (
                        <g>
                          <path d="M12 5C7.68 5 4 8.33 1.94 11.45l-.35.55.36.55C4 15.67 7.68 19 12 19s8-3.33 10.06-6.45l.36-.55-.36-.55C20 8.33 16.32 5 12 5Zm0 12c-3.28 0-6.21-2.49-8-5 1.8-2.51 4.73-5 8-5 3.27 0 6.21 2.49 8 5-1.79 2.51-4.72 5-8 5Z"></path>
                          <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"></path>
                        </g>
                      ) : (
                        <g>
                          <path d="M4.71 3.29a1.004 1.004 0 1 0-1.42 1.42l5.63 5.63a3.5 3.5 0 0 0 4.74 4.74l5.63 5.63a1 1 0 0 0 1.42 0 1.002 1.002 0 0 0 0-1.42l-16-16ZM12 13.5a1.5 1.5 0 0 1-1.5-1.5v-.07l1.56 1.56-.06.01Z"></path>
                          <path d="M12.22 17c-4.3.1-7.12-3.59-8-5a13.7 13.7 0 0 1 2.24-2.72L5 7.87a15.89 15.89 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.484 9.484 0 0 0 3.23-.67l-1.58-1.58a7.746 7.746 0 0 1-1.7.25Zm9.65-5.5c-.64-1.11-4.17-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67l1.58 1.58a7.74 7.74 0 0 1 1.7-.25c4.29-.11 7.11 3.59 8 5a13.705 13.705 0 0 1-2.29 2.72L19 16.13a15.893 15.893 0 0 0 2.91-3.63 1.001 1.001 0 0 0-.04-1Z"></path>
                        </g>
                      )}
                    </svg>
                  </button>
                  {errors.current_password && <span className="validate-error-message">{errors.current_password}</span>}
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label" htmlFor="new-password">
                  {t('password.new')}
                </label>
                <div className="relative">
                  <input
                    className={`form-control form-control--lg ${errors.new_password ? 'validate-error' : ''}`}
                    onFocus={() => onFocus('new_password')}
                    onChange={({ target }) => handleFormChange('new_password', target.value)}
                    value={form.new_password}
                    type={shownPassword.includes('new_password') ? 'text' : 'password'}
                    placeholder={t('password.new_placeholder')}
                  />
                  <button className="absolute right-3 top-3 text-black text-opacity-40" type="button">
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      onClick={() => setShownPassword(xor(shownPassword, ['new_password']))}
                    >
                      {shownPassword.includes('new_password') ? (
                        <g>
                          <path d="M12 5C7.68 5 4 8.33 1.94 11.45l-.35.55.36.55C4 15.67 7.68 19 12 19s8-3.33 10.06-6.45l.36-.55-.36-.55C20 8.33 16.32 5 12 5Zm0 12c-3.28 0-6.21-2.49-8-5 1.8-2.51 4.73-5 8-5 3.27 0 6.21 2.49 8 5-1.79 2.51-4.72 5-8 5Z"></path>
                          <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"></path>
                        </g>
                      ) : (
                        <g>
                          <path d="M4.71 3.29a1.004 1.004 0 1 0-1.42 1.42l5.63 5.63a3.5 3.5 0 0 0 4.74 4.74l5.63 5.63a1 1 0 0 0 1.42 0 1.002 1.002 0 0 0 0-1.42l-16-16ZM12 13.5a1.5 1.5 0 0 1-1.5-1.5v-.07l1.56 1.56-.06.01Z"></path>
                          <path d="M12.22 17c-4.3.1-7.12-3.59-8-5a13.7 13.7 0 0 1 2.24-2.72L5 7.87a15.89 15.89 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.484 9.484 0 0 0 3.23-.67l-1.58-1.58a7.746 7.746 0 0 1-1.7.25Zm9.65-5.5c-.64-1.11-4.17-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67l1.58 1.58a7.74 7.74 0 0 1 1.7-.25c4.29-.11 7.11 3.59 8 5a13.705 13.705 0 0 1-2.29 2.72L19 16.13a15.893 15.893 0 0 0 2.91-3.63 1.001 1.001 0 0 0-.04-1Z"></path>
                        </g>
                      )}
                    </svg>
                  </button>
                  {errors.new_password && <span className="validate-error-message">{errors.new_password}</span>}
                </div>
              </div>
              <div className="mb-5">
                <label className="form-label" htmlFor="confirm-new-password">
                  {t('password.new_again')}
                </label>
                <div className="relative">
                  <input
                    className={`form-control form-control--lg ${errors.new_password2 ? 'validate-error' : ''}`}
                    onFocus={() => onFocus('new_password2')}
                    onChange={({ target }) => handleFormChange('new_password2', target.value)}
                    value={form.new_password2}
                    type={shownPassword.includes('new_password2') ? 'text' : 'password'}
                    placeholder={t('password.new_again_placeholder')}
                  />
                  <button className="absolute right-3 top-3 text-black text-opacity-40" type="button">
                    <svg
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      onClick={() => setShownPassword(xor(shownPassword, ['new_password2']))}
                    >
                      {shownPassword.includes('new_password2') ? (
                        <g>
                          <path d="M12 5C7.68 5 4 8.33 1.94 11.45l-.35.55.36.55C4 15.67 7.68 19 12 19s8-3.33 10.06-6.45l.36-.55-.36-.55C20 8.33 16.32 5 12 5Zm0 12c-3.28 0-6.21-2.49-8-5 1.8-2.51 4.73-5 8-5 3.27 0 6.21 2.49 8 5-1.79 2.51-4.72 5-8 5Z"></path>
                          <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"></path>
                        </g>
                      ) : (
                        <g>
                          <path d="M4.71 3.29a1.004 1.004 0 1 0-1.42 1.42l5.63 5.63a3.5 3.5 0 0 0 4.74 4.74l5.63 5.63a1 1 0 0 0 1.42 0 1.002 1.002 0 0 0 0-1.42l-16-16ZM12 13.5a1.5 1.5 0 0 1-1.5-1.5v-.07l1.56 1.56-.06.01Z"></path>
                          <path d="M12.22 17c-4.3.1-7.12-3.59-8-5a13.7 13.7 0 0 1 2.24-2.72L5 7.87a15.89 15.89 0 0 0-2.87 3.63 1 1 0 0 0 0 1c.63 1.09 4 6.5 9.89 6.5h.25a9.484 9.484 0 0 0 3.23-.67l-1.58-1.58a7.746 7.746 0 0 1-1.7.25Zm9.65-5.5c-.64-1.11-4.17-6.68-10.14-6.5a9.48 9.48 0 0 0-3.23.67l1.58 1.58a7.74 7.74 0 0 1 1.7-.25c4.29-.11 7.11 3.59 8 5a13.705 13.705 0 0 1-2.29 2.72L19 16.13a15.893 15.893 0 0 0 2.91-3.63 1.001 1.001 0 0 0-.04-1Z"></path>
                        </g>
                      )}
                    </svg>
                  </button>
                  {errors.new_password2 && <span className="validate-error-message">{errors.new_password2}</span>}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-end space-x-5">
          <button disabled={isSaving} className="btn btn-light" type="button" onClick={closeForm}>
            {t('actions.cancel')}
          </button>
          <button
            className="btn btn-blue"
            type="button"
            onClick={handleDialogSave}
            disabled={isSaveDisabled() || isSaving}
          >
            <FASpinner containerClass="mr-2" show={isSaving} />
            {t('actions.save')}
          </button>
        </div>
      </form>
    </ModalBase>
  );
});

export default Settings;
