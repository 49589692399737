import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
/*
import econom from 'assets/images/cars/econom.png';
import child from 'assets/images/cars/child.png';
import comfort from 'assets/images/cars/comfort.png';
import cargo from 'assets/images/cars/cargo.png';
import comfortplus from 'assets/images/cars/comfortplus.png';
import delivery from 'assets/images/cars/delivery.png';
import exclusive from 'assets/images/cars/exclusive.png';
import minivan from 'assets/images/cars/minivan.png';
import premium from 'assets/images/cars/premium.png';
*/
import FASpinner from 'components/FASpinner';
import { isCarClassNameKnown } from 'utils/lib/isCarClassNameKnown';
import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import { TariffClass } from 'types/types';
import { formatPrice } from 'utils/lib/formatPrice';

/*
const carsIcons = {
  econom,
  child,
  comfort,
  cargo,
  comfortplus,
  delivery,
  exclusive,
  minivan,
  premium
}
*/

type CurrencySignProps = {
  code?: string;
};

export const CurrencySign = ({ code = '₽' }: CurrencySignProps): JSX.Element | null => {
  return <span className="currency-sign">{code}</span>;
};

interface TariffCardProps {
  tariff: TariffClass;
  showPrice?: boolean;
  active?: boolean;
  onClick?: any;
  inProgress?: boolean;
}

export const TariffCard = observer(
  ({ tariff, showPrice = true, active = false, onClick, inProgress = false }: TariffCardProps): JSX.Element | null => {
    const { name = '', providers_info } = tariff;
    let maxPrice;
    let minPrice;
    const prices: number[] = Object.values(providers_info || {})
      .map((provider) => provider.price)
      .filter((price) => !!price) as number[];
    if (prices?.length > 0) {
      maxPrice = Math.max(...prices);
      minPrice = Math.min(...prices);
      if (maxPrice === minPrice) {
        maxPrice = undefined;
      }
    }
    const { t } = useTranslation('tariff');
    const title = t(name) === name ? tariff?.title || name : t(name);
    const opacity = 1; // active && !inProgress ? 1 : 0.5;

    if (!name) return null;

    //backgroundImage: `url(${isCarClassNameKnown(name) && carsIcons[name] ? carsIcons[name] : econom})`,
    return (
      <div
        onClick={() => {
          if (inProgress) return;
          onClick(tariff);
        }}
        className={`flex relative tariff ${active ? 'tariff-active bg-graylighter' : ''} ${inProgress ? 'shadow-thin' : ''}`}
      >
        <div
          className="tariff-image"
          style={{
            opacity,
            backgroundSize: name === 'econom' ? '65px 65px' : 'cover',
            backgroundImage: `url(${tariff.icon512})`,
          }}
        />
        <span className="tariff-title one-line-text" style={{ opacity }}>
          {title}
        </span>
        {maxPrice || minPrice ? (
          <span className="tariff-price text-center" style={{ opacity }}>
            {`${formatPrice(minPrice, '0,0', 0, false) || ''}${minPrice && maxPrice ? ' - ' : ''}${formatPrice(maxPrice, '0,0', 0, false) || ''} ${CURRENT_CURRENCY}`}
          </span>
        ) : null}
        {/* {
        time > 0 ? (
          <span className='tariff-time' style={{ marginBottom: showPrice ? 0 : 2, opacity }}>{time > 0 ? `${time} ${t('tariff_mins')}.` : ''}</span>
        ) : null
      } */}
        <FASpinner
          containerClass="absolute flex top-0 left-0 right-0 bottom-0 justify-center items-center z-10"
          className="fa-2x"
          show={inProgress}
        />
      </div>
    );
  },
);
