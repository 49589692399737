import { Limit } from 'components/Layout/Limit';
import { observer } from 'mobx-react-lite';
import React, { HTMLAttributes } from 'react';
import { useStores } from 'stores';
import { ProfileRole } from 'types/types';

interface ProfileCardProps extends HTMLAttributes<HTMLAnchorElement> {
  profile: ProfileRole;
  keepIcon?: boolean;
  showLimits?: boolean;
}

export const ProfileCard = observer(({ profile, keepIcon, showLimits, ...props }: ProfileCardProps): JSX.Element => {
  const { ordersStore } = useStores();
  const { isVip } = ordersStore || {};

  return (
    <a href="#" {...props}>
      <div className="flex items-center min-w-0">
        <img
          className={`w-10 h-10 ${!keepIcon ? 'hidden' : ''} lg:block`}
          src={profile?.customer?.avatar || `${process.env.PUBLIC_URL}/i/${isVip ? 'logoVip' : 'logo'}.svg`}
          role="img"
          alt="Логотип"
        />
        <span className="text-xl mr-3 ml-3 font-medium tracking-tight text-black company-name">
          {profile?.label !== profile?.customer?.name && profile?.customer?.name ? `${profile?.customer?.name} - ` : ''}
          <span className={`${profile?.label !== profile?.customer?.name ? 'font-semibold mr-3' : ''}`}>
            {profile?.label || 'SwiftDrive'}
          </span>
        </span>
      </div>
      {showLimits && profile?.limits ? <Limit limits={profile.limits} className="flex-col mt-1 ml-2" /> : null}
    </a>
  );
});
