import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';
import Zone from './zone';
import ZoneDialog from './zoneDialog';
import ConfirmModal from 'components/Modal/confirmModal';

interface RegionesProps {}

export interface Region {
  title: string;
  coords: (number | string)[];
}

const Zones = observer(({}: RegionesProps): JSX.Element => {
  const { t } = useTranslation(['policy', 'confirmation', 'errors']);
  const { rolesStore } = useStores();
  const { activeGroup } = rolesStore || {};
  const { zones } = activeGroup || {};
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const regionTypeRef = useRef<'to' | 'from'>('from');

  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const openConfirm = (): void => setVisibleConfirm(true);

  const closeDialog = (): void => setVisibleDialog(false);
  const closeConfirm = (): void => setVisibleConfirm(false);

  const renderRegiones = (): any[] | null => {
    let result: any[] = [];
    const fromLength = zones?.from?.length || 0;
    const toLenght = zones?.to?.length || 0;
    if (!(fromLength > 0 || toLenght > 0)) return null;
    const biggestLenght = toLenght > fromLength ? toLenght : fromLength;

    for (let i = 0; i < biggestLenght; i++) {
      result.push(
        <tr>
          <Zone direction="from" index={i} zone={i < zones.from?.length ? zones.from[i] : null} />
          <Zone direction="to" index={i} zone={i < zones.to?.length ? zones.to[i] : null} />
        </tr>,
      );
    }

    return result;
  };

  const onAddRegion = (place: 'to' | 'from'): void => {
    setVisibleDialog(true);
    regionTypeRef.current = place;
  };

  const onDeleteRule = async (): Promise<void> => {
    await rolesStore.updateActiveGroup({ zones: {} });
    if (rolesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedRuleDeletion', { ns: 'errors' })} \"${t('rule_dialog.zones.title') || ''}\": ${formatError(rolesStore?.addingError, t)}`,
      });
    }
  };

  return (
    <div className="pt-[54px] pb-5 px-4 relative md:px-0">
      <button className="btn-close absolute right-8 top-4 md:right-4 md:top-5" type="button" onClick={openConfirm}>
        <svg className="w-[22px] h-[22px]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
          <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
        </svg>
      </button>
      <div className="mb-5">
        <label className="form-label absolute top-9 left-4" htmlFor="scenario">
          {t('current.rules.zones.zones')}
        </label>
        <div className="area mt-5 md:rounded-lg md:ml-5 md:mr-5" style={{ overflow: 'auto' }}>
          <table className="table" style={{ width: '100%', minWidth: 450 }}>
            <thead>
              <tr>
                <th>
                  <button className="font-semibold w-full text-left" type="button" onClick={() => onAddRegion('from')}>
                    {t('current.rules.zones.add_from')}
                  </button>
                </th>
                <th onClick={() => onAddRegion('from')} style={{ width: 40 }}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </th>
                <th>
                  <button className="font-semibold w-full text-left" type="button" onClick={() => onAddRegion('to')}>
                    {t('current.rules.zones.add_to')}
                  </button>
                </th>
                <th onClick={() => onAddRegion('to')} style={{ width: 40, paddingLeft: 0 }} className="text-right">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </th>
              </tr>
            </thead>
            <tbody>{renderRegiones()}</tbody>
          </table>
        </div>
      </div>
      <ZoneDialog
        zone={undefined}
        visible={visibleDialog}
        onClose={closeDialog}
        direction={regionTypeRef.current}
        index={-1}
      />
      <ConfirmModal
        title={t('deletion_title', { ns: 'confirmation' })}
        text={t('deletion_confirmation', { ns: 'confirmation' })}
        visible={visibleConfirm}
        onCancel={closeConfirm}
        onClose={closeConfirm}
        onConfirm={() => {
          closeConfirm();
          onDeleteRule();
        }}
      />
    </div>
  );
});

export default Zones;
