import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import apiClient, { LimitsApi } from './api';
import { AxiosError } from 'axios';
import { Limit } from 'types/types';

export default class LimitsStore extends BaseStore {
  api: LimitsApi;
  @observable createError: AxiosError | undefined;
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Limits']);
    this.api = new apiClient(rootStore.specifications['Limits'].url);
    makeObservable(this);
  }

  @observable isFetschingallLimitsIdsInProgress: boolean = false;

  async createLimit(employee_id: number, data: Limit): Promise<void> {
    try {
      runInAction(() => {
        this.createError = undefined;
      });
      await this.api.createLimit(employee_id, data);
    } catch (err) {
      this.captureError(err);
      this.createError = err as AxiosError;
    }
  }
  async getLimit(employee_id: number): Promise<Limit | null> {
    try {
      return await this.api.getLimit(employee_id);
    } catch (err) {
      this.captureError(err);
      return null;
    }
  }
}
