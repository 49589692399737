export default {
  title: 'Orders',
  loading: 'Loading data',
  error: 'Error',
  unAuthedOrderError: 'The requested order was not found',
  thead: {
    id: 'ID',
    status: 'Status',
    address: 'Address',
    full_name: 'Full Name',
    company: 'Company',
    driver: 'Driver',
    driver_name: 'Driver’s name',
    driver_car: 'Driver’s car',
    class: 'Class',
    cost: 'Cost (with VAT)',
    comment: 'Comment',
    time: 'Time',
  },
  trow: {
    employee: 'Employee',
    paid_waiting: 'Paid Waiting',
    ride: 'Ride',
    full_time: 'Total Time',
    minutes_short: 'm',
    minutes: 'min',
    arrival_time: 'Arrival Time',
    guest: '(passenger / guest)',
    support: 'Support Service',
    entrance: 'Entrance',
    date: 'DD.MM.YY [at] HH:mm',
  },
  info: {
    remaining_time: 'Remaining travel time',
    will_arrive_soon: 'The driver will arrive soon',
    arrived: 'The driver is waiting',
    search_header: 'Order received',
    search: 'Searching for a suitable driver',
    'search.vip': 'Order received. Searching for a suitable driver',
    time: 'Travel time',
    will_arrive: 'The driver will arrive in',
    willarrive_vip: 'In',
    mins: 'minutes',
    mins_s1: 'minute',
    mins_s2: 'minutes',
    car: 'Car',
    car_number: 'Car Number',
    help_phone: 'Contact Center Phone',
    order_id: 'Order Number',
    route: 'Route',
    phone: 'Phone',
  },
  details: {
    id: 'ID',
    manager: 'Manager',
    status: 'Status',
    start_transporting_time: 'Start Time',
    finished_time: 'End Time',
    cancelled_time: 'Cancellation Time',
    order_time: 'Order Creation Time',
    time: 'Time',
    source: 'Departure',
    interim_destination: 'Stop',
    destination: 'Destination',
    employee: 'Employee',
    employee_name: 'Employee',
    select_employee: 'Select an employee',
    phone: 'Phone',
    driver_phone: 'Driver’s Phone',
    class: 'Class',
    car: 'Car',
    is_toll_road: 'Toll Roads',
    is_toll_road_paid_by_driver: 'paid by driver',
    is_toll_road_paid_by_client: 'paid by client',
    cost_customer: 'Cost excluding VAT, RUB',
    cost_customer_with_vat: 'Cost including VAT, RUB',
    cost_customer_estimate: 'Estimated cost including VAT, RUB',
    cost_customer_estimate_inc_tax: 'Estimated cost including VAT, RUB',
    cost_customer_estimate_exc_tax: 'Estimated cost excluding VAT, RUB',
    paid_waiting_time_s: 'Paid Waiting',
    customer_name: 'Customer Name',
    provider: 'Provider',
    provider_order_id: 'Provider’s Order Number',
    comment: 'Your wishes for the driver',
    guest_link: 'Link to guest drive',
    cancelled_by: 'Order canceled by',
    cancelled_client: 'Canceled by user',
    cancelled_driver: 'Canceled by driver',
    driver_not_found: 'No driver was found for the order',
    orderer: 'Order placed by',
    internal_comment: 'Internal Comment',
    additional_fields: 'Additional Fields',
    actions: {
      cancel: 'Cancel',
      ritm: 'Place in Rhythm',
      reorder: 'Find Driver',
      edit: 'Edit',
      order_again: 'Order Again',
      show: 'Show',
      copy: 'Copy',
      return_trip: 'Return Trip',
      employee_rides: 'Employee rides',
      receipt: 'Receipt',
    },
  },
  filter: {
    search_placeholder: 'Search',
    title: 'Filters',
    status: 'Status',
    full_name: 'Full Name',
    id: 'ID',
    class: 'Class',
    company: 'Company',
    date: 'DD.MM.YY-DD.MM.YY',
    phone: 'Phone',
    employee_name: 'Full Name',
    source: 'Address',
    city: 'City',
    is_toll_road: 'Toll Roads',
    is_promo: 'Promo',
    actions: {
      apply: 'Apply',
    },
  },
};
