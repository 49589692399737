export default {
  title: 'Поездки',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  unAuthedOrderError: 'Запрашиваемый заказ не найден',
  thead: {
    id: 'ID',
    status: 'Статус',
    address: 'Адрес',
    full_name: 'ФИО',
    company: 'Компания',
    driver: 'Водитель',
    driver_name: 'ФИО водителя',
    driver_car: 'Машина водителя',
    class: 'Класс',
    cost: 'Стоимость c НДС',
    comment: 'Комментарий',
    time: 'Время',
  },
  trow: {
    employee: 'Сотрудник',
    paid_waiting: 'Платное ожидание',
    ride: 'Поездка',
    full_time: 'Общее время',
    minutes_short: 'м',
    minutes: 'мин',
    arrival_time: 'Время подачи',
    guest: '(пассажир / гость)',
    support: 'Служба поддержки',
    entrance: 'подъезд',
    date: 'DD.MM.YY [в] HH:mm',
  },
  info: {
    remaining_time: 'Оставшееся время в пути',
    will_arrive_soon: 'Водитель скоро прибудет',
    arrived: 'Водитель ожидает',
    search_header: 'Заказ получен',
    search: 'Ищем подходящего водителя',
    'search.vip': 'Заказ получен. Ищем подходящего водителя',
    time: 'Время в пути',
    will_arrive: 'Водитель прибудет через',
    willarrive_vip: 'Через',
    mins: 'минут',
    mins_s1: 'минута',
    mins_s2: 'минуты',
    car: 'Автомобиль',
    car_number: 'Номер автомобиля',
    help_phone: 'Телефон Контакт Центра',
    order_id: 'Номер заказа',
    route: 'Маршрут',
    phone: 'Телефон',
  },
  details: {
    id: 'ID',
    manager: 'Менеджер',
    status: 'Статус',
    start_transporting_time: 'Время начала',
    finished_time: 'Время окончания',
    cancelled_time: 'Время отмены',
    order_time: 'Время создания заказа',
    time: 'Время',
    source: 'Отправление',
    interim_destination: 'Остановка',
    destination: 'Назначение',
    employee: 'Сотрудник',
    employee_name: 'Сотрудник',
    select_employee: 'Выберите сотрудника',
    phone: 'Телефон',
    driver_phone: 'Телефон водителя',
    class: 'Класс',
    car: 'Автомобиль',
    is_toll_road: 'Платные дороги',
    is_toll_road_paid_by_driver: 'оплачивается водителем',
    is_toll_road_paid_by_client: 'оплачивается клиентом',
    cost_customer: 'Стоимость без НДС, руб.',
    cost_customer_with_vat: 'Стоимость с НДС, руб.',
    cost_customer_estimate: 'Расчётная стоимость с НДС, руб.',
    cost_customer_estimate_inc_tax: 'Расчётная стоимость с НДС, руб.',
    cost_customer_estimate_exc_tax: 'Расчётная стоимость, без НДС, руб.',
    paid_waiting_time_s: 'Платное ожидание',
    customer_name: 'Наименование клиента',
    provider: 'Поставщик',
    provider_order_id: 'Номер заказа у поставщика',
    comment: 'Ваши пожелания для водителя',
    guest_link: 'Ссылка на поездку',
    cancelled_by: 'Кем отменен заказ',
    cancelled_client: 'Отменен пользователем',
    cancelled_driver: 'Отменен водителем',
    driver_not_found: 'По заказу не был найден водитель',
    orderer: 'Кто оформил заказ',
    internal_comment: 'Внутренний комментарий',
    additional_fields: 'Дополнительные поля',
    actions: {
      cancel: 'Отменить',
      ritm: 'Разместить в Ритм',
      reorder: 'Поиск водителя',
      edit: 'Редактировать',
      order_again: 'Заказать повторно',
      show: 'Показать',
      copy: 'Копировать',
      return_trip: 'Обратная поездка',
      employee_rides: 'Поездки сотрудника',
      receipt: 'Чек',
    },
  },
  filter: {
    search_placeholder: 'Поиск',
    title: 'Фильтры',
    status: 'Статус',
    full_name: 'ФИО',
    id: 'ID',
    class: 'Класс',
    company: 'Компания',
    date: 'ДД.ММ.ГГ-ДД.ММ.ГГ',
    phone: 'Телефон',
    employee_name: 'ФИО',
    source: 'Адрес',
    city: 'Город',
    is_toll_road: 'Платные дороги',
    is_promo: 'Промо',
    actions: {
      apply: 'Применить',
    },
  },
};
