import React, { JSX, useEffect, useState } from 'react';
import moment from 'moment';
import { DateTimeField } from 'components/Inputs/DateTimeField';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TimeRestrictions } from '../../modules/Employees/LimitTab';

interface TimeRestrictionProps {
  restriction: TimeRestrictions;
  onChange: (restriction: any) => void;
  onDelete: () => void;
  ind: number;
}

const LOCAL_TIME_OFFSET = new Date().getTimezoneOffset() * 60;

export const TimeRestriction: React.FC<TimeRestrictionProps> = observer(
  ({ restriction, onChange, onDelete, ind }): JSX.Element => {
    const { t } = useTranslation(['employees']);
    const [type, setType] = useState(restriction.type);
    const [days, setDays] = useState(restriction.days || []);
    const [minutes, setMinutes] = useState(restriction.minutes);
    const [startTime, setStartTime] = useState(restriction.start_time);
    const [endTime, setEndTime] = useState(restriction.end_time);

    useEffect(() => {
      setStartTime(restriction.start_time);
    }, [restriction?.start_time]);

    useEffect(() => {
      setEndTime(restriction.end_time);
    }, [restriction?.end_time]);

    const handleTypeChange = (value: string) => {
      setType(value);
      let newDays: string[] = [];
      let newRestrictions = {
        type: value,
        minutes: restriction.minutes,
        ...(value === 'interval'
          ? {
              start_time: restriction.start_time,
              end_time: restriction.end_time,
              max_order_count: restriction.max_order_count,
            }
          : {}),
        ...(value === 'days'
          ? {
              days: [],
            }
          : {}),
        ...(value === 'weekdays'
          ? {
              days: ['mo', 'tu', 'we', 'th', 'fr'],
            }
          : {}),
        ...(value === 'weekends'
          ? {
              days: ['su', 'sa'],
            }
          : {}),
      };
      setDays(newDays);
      onChange(newRestrictions);
    };

    const handleDateChange = (key: 'start_time' | 'end_time', value: number) => {
      if (key === 'start_time') {
        setStartTime(value);
      } else {
        setEndTime(value);
      }
      onChange({ ...restriction, [key]: value });
    };

    const handleDaysChange = (day: string) => {
      const newDays = days.includes(day) ? days.filter((d) => d !== day) : [...days, day];
      setDays(newDays);
      onChange({ ...restriction, days: newDays });
    };

    const handleChangeMinutes = ([start, end]) => {
      const newMinutes = {
        ...minutes,
        start: start ? moment(start).diff(moment().startOf('day'), 'minutes') : 0,
        end: end ? moment(end).diff(moment().startOf('day'), 'minutes') : 1439,
      };
      setMinutes(newMinutes);
      onChange({ ...restriction, minutes: newMinutes });
    };

    return (
      <div className="time-restrictions mb-4 relative">
        {
          <span className="time-restrictions__title">
            {`${t('limits.time_limit')} ${ind + 1}`}
            {restriction.type === 'interval' && (
              <span className="time-restrictions__info">{t('limits.time_interval_info')}</span>
            )}
          </span>
        }
        <button className="absolute top-4 right-0" type="button" onClick={onDelete}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 6.5H22" stroke="#737373" strokeWidth="2" strokeLinecap="round" />
            <path
              d="M7 6.5L7.46257 3.35451C7.53475 2.8637 7.95584 2.5 8.45193 2.5H15.5481C16.0442 2.5 16.4652 2.8637 16.5374 3.35451L17 6.5"
              stroke="#737373"
              strokeWidth="2"
            />
            <path
              d="M5 6.5L5.8828 20.6248C5.94868 21.6788 6.82278 22.5 7.8789 22.5H16.1211C17.1772 22.5 18.0513 21.6788 18.1172 20.6248L19 6.5"
              stroke="#737373"
              strokeWidth="2"
            />
            <path d="M9.5 9.5V19.5" stroke="#737373" strokeWidth="2" strokeLinecap="round" />
            <path d="M14.5 9.5V19.5" stroke="#737373" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>

        <div className="flex">
          <div className="mb-4 flex-1">
            <label className="form-label">{t('limits.time_limit_type')}</label>
            <select className="form-control" value={type} onChange={(e) => handleTypeChange(e.target.value)}>
              {['interval', 'weekdays', 'weekends', 'days'].map((v) => (
                <option key={`type-${v}`} value={v}>
                  {t(`limits.type.${v}`)}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4 ml-3 flex-1">
            <label className="form-label">{t('limits.time_limit_period')}</label>
            <DateTimeField
              dateFormat="HH:mm"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              selectsRange={true}
              startDate={moment()
                .startOf('day')
                .add(minutes?.start || 0, 'minutes')
                .toDate()}
              endDate={moment()
                .startOf('day')
                .add(minutes?.end || 1439, 'minutes')
                .toDate()}
              onChange={handleChangeMinutes}
            />
          </div>
        </div>

        {type === 'days' && (
          <div className="mb-4">
            <div className="flex gap-2">
              {['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map((day) => (
                <label className="time-restrictions__day flex-1" key={day}>
                  <div className="custom-checkbox-content">
                    <input type="checkbox" checked={days.includes(day)} onChange={() => handleDaysChange(day)} />
                    <div className={`custom-checkbox ${days.includes(day) ? 'custom-checkbox-checked' : ''}`}></div>
                    <div className="custom-checkbox-name">{t(`limits.week.${day}`)}</div>
                  </div>
                </label>
              ))}
            </div>
          </div>
        )}
        {type === 'interval' && (
          <div className="mb-4">
            <label className="form-label" htmlFor="max_order_count">
              {t('limits.max_order_count')}
            </label>
            <input
              className={`form-control py-3`}
              type="text"
              onChange={(e) =>
                !isNaN(Number(e.target.value)) && onChange({ ...restriction, max_order_count: Number(e.target.value) })
              }
              id="max_order_count"
              placeholder={t('limits.max_order_count')}
              value={restriction.max_order_count || ''}
            />
          </div>
        )}

        {type === 'interval' && (
          <div className="mb-4">
            <div className="flex gap-2">
              <div className="flex-1">
                <label className="form-label">{t('limits.time_limit_from')}</label>
                <DateTimeField
                  name={`startTime-${restriction.title || ind}-${ind}`}
                  id={`startTime-${restriction.title || ind}-${ind}`}
                  key={`startTime-${restriction.title || ind}-${ind}`}
                  selected={
                    startTime
                      ? new Date(moment((startTime + LOCAL_TIME_OFFSET) * 1000).format('YYYY-MM-DDT00:00:00.SSS[Z]'))
                      : null
                  }
                  onChange={(date) => {
                    const v = date ? Math.ceil(moment(date).valueOf() / 1000) - LOCAL_TIME_OFFSET : 0;
                    const d = new Date(moment(v * 1000).format('YYYY-MM-DDT00:00:00.SSS[Z]'));
                    handleDateChange('start_time', date ? Math.ceil(moment(d).valueOf() / 1000) : 0);
                  }}
                  dateFormat="DD.MM.YYYY"
                  maxDate={endTime ? new Date(endTime * 1000) : null}
                />
              </div>
              <div className="flex-1 ml-3">
                <label className="form-label">{t('limits.time_limit_to')}</label>

                <DateTimeField
                  name={`endTime-${restriction.title || ind}-${ind}`}
                  id={`endTime-${restriction.title || ind}-${ind}`}
                  key={`endTime-${restriction.title || ind}-${ind}`}
                  selected={
                    endTime
                      ? new Date(moment.utc((endTime + LOCAL_TIME_OFFSET) * 1000).format('YYYY-MM-DDT00:00:00.SSS[Z]'))
                      : null
                  }
                  onChange={(date) => {
                    const v = date ? Math.ceil(moment(date).valueOf() / 1000) - LOCAL_TIME_OFFSET : 0;
                    const d = new Date(moment(v * 1000).format('YYYY-MM-DDT23:59:59.SSS[Z]'));
                    handleDateChange('end_time', v ? Math.ceil(moment(d).valueOf() / 1000) : 0);
                  }}
                  dateFormat="DD.MM.YYYY"
                  minDate={startTime ? new Date(startTime * 1000) : null}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
