import React, { useState } from 'react';

export const Switcher = ({
  name,
  description,
  value,
  onChange,
  children,
}: {
  name: string;
  description?: string;
  value: boolean;
  children?: React.ReactNode;
  onChange: (newValue: boolean) => void;
}) => {
  return (
    <div className="mt-5">
      <div
        className="flex items-center justify-between w-full p-2 bg-graylight rounded-lg cursor-pointer"
        onClick={() => onChange(!value)}
      >
        <div className="flex flex-col">
          <span className="font-semibold">{name}</span>
          {description && <span className="text-gray-600">{description}</span>}
        </div>

        <div className={`flex w-[50px] h-[31px] p-1 ${value ? 'bg-active' : 'bg-gray'} rounded-[50px] relative`}>
          <div
            className={`m-[2px] w-[27px] h-[27px] bg-black rounded-[50px] shadow-md transform duration-300 ${
              value ? 'translate-x-[19px]' : 'translate-x-0'
            }`}
          ></div>
        </div>
      </div>
      {value && children}
    </div>
  );
};
