export default {
  title: 'Сотрудники',
  action: {
    add: 'Добавить сотрудника',
    edit: 'Редактирование',
    import: 'Импорт сотрудников',
    export: 'Экспорт',
    exporting: 'Экспортирую',
    rides: 'Поездки',
    resend_sms: 'Отправить СМС повторно',
  },
  search_placeholder: 'Поиск',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  thead: {
    full_name: 'ФИО',
    phone: 'Телефон',
    code: 'Код сотрудника',
    department_id: 'Департамент',
    department_code: 'Код филиала',
    department_name: 'Отдел',
    branch: 'Филиал',
    role: 'Роль',
    limit: 'Лимит',
    remainder: 'Остаток',
    order_right: 'Права на заказ',
    status: 'Статус',
    app_last_visit: 'Время последнего входа',
  },
  trow: {
    can_order: 'Корп. поездки для себя',
    can_order_others: 'Корп. поездки для других',
    limit_personal: 'персональный',
    limit_group: 'на группу',
  },
  import: {
    imported_number: 'Импортировано записей',
    failed_number: 'Пропущено записей',
    errors: 'Ошибки при импорте',
    line: 'Строка',
    import_process: 'Импорт',
    from: 'из',
    close: 'Закрыть',
    dropzone_text: 'Нажмите или перетащите файл в эту область',
    import_example: 'Пример файла для импорта',
    import_file_name: 'импорт',
    end: 'Импорт завершён.',
    success: 'Успешно импортировано',
    lines: 'строк',
    lines_s1: 'строка',
    lines_s2: 'строки',
  },
  filter: {
    actions: {
      apply: 'Применить',
    },
    role: 'Роль',
    title: 'Фильтры',
  },
  dialog: {
    full_name: 'ФИО',
    phone: 'Телефон',
    email: 'E-mail',
    employee_code: 'Код сотрудника',
    department_id: 'Департамент',
    department_code: 'Код филиала',
    department_name: 'Отдел',
    order_right: 'Права на заказ',
    add: 'Добавить',
    save: 'Cохранить',
    cancel: 'Отменить',
    policy_group: 'Группа политики поездок',
    passenger: 'Пассажир',
    coordinator: 'Персональный координатор',
    not_selected: 'Не выбрано',
    can_order: 'Заказ для себя',
    can_order_text: 'Может заказать корпоративные поездки для себя',
    can_order_others: 'Заказ для других',
    can_order_others_text: 'Может заказывать поездки другим сотрудникам',
    active: 'Активность',
    active_text: 'Активен',
    is_vip: 'Prime-тема',
    is_vip_text: 'Prime',
    send_welcome_sms: 'Отправить приглашение по СМС',
    limit_month_amount: 'Лимит, руб. с НДС',
    without_limit: 'Без лимита',
    active_from_time: 'Активность с',
    active_to_time: 'Активность по',
    time: 'Время',
    tabs: {
      limits: 'Лимиты',
      data: 'Персональные данные',
    },
  },
  status: {
    active: 'Активен',
    inactive: 'Неактивен',
  },
  limits: {
    cost_restriction: 'Ограничения по сумме',
    time_restrictions: 'Ограничения по времени',
    class_restrictions: 'Ограничения по классам',
    chosen: 'Выбрано',
    restrictions: 'ограничений',
    restrictions_s1: 'ограничение',
    restrictions_s2: 'ограничения',
    cost: 'Лимит, руб с НДС',
    period: 'Период',
    total_cost_inc_tax: 'Максимальная сумма трат на сервис',
    max_order_count: 'Количество поездок в лимит',
    cost_type: {
      month: 'Месяц',
      day: 'День',
      week: 'Неделя',
    },
    interval: 'Интервал',
    time_limit_type: 'Тип лимита',
    time_limit_period: 'Время',
    time_limit_from: 'Действует с',
    time_limit_to: 'Действует по',
    add: '+ Лимит',
    time_limit: 'Интервал',
    time_interval_info: 'Даты имеют выше приоритет',
    classes_all: 'Выбрать все',
    type: {
      interval: 'Указанные даты',
      weekdays: 'Будни',
      weekends: 'Выходные',
      days: 'Определённые дни',
    },
    week: {
      mo: 'Пн',
      tu: 'Вт',
      we: 'Ср',
      th: 'Чт',
      fr: 'Пт',
      sa: 'Сб',
      su: 'Вс',
    },
  },
};
