import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { SearchBar } from 'components/SearchBar';
import { Pagination } from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Coordinator } from '..';
import { Employee, TariffClass } from 'types/types';
import { Checkbox } from 'components/Inputs/Checkbox';
import ModalBase from 'components/ModalBase';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const Dialog = observer(({ onClose, isOpen }: Props) => {
  const {
    coordinatorsStore,
    coordinatorsStore: { editingCoordinator: user },
    ordersClassesStore,
  } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation(['coordinators', 'classes', 'confirmation']);
  const { t: tariffT } = useTranslation(['tariff']);

  useEffect(() => {
    if (isOpen) {
      ordersClassesStore.fetchList(false, {}, 'id desc', 0, 20, true, false).then(() => setIsDataReady(true));
    } else {
      setIsDataReady(false);
    }
  }, [isOpen]);

  const updatePermissions = (updates: Partial<Coordinator['permissions']>): void => {
    coordinatorsStore.updateEditingCoordinator({ permissions: { ...(user?.permissions || {}), ...updates } });
  };

  const resetClassesPermissions = (): void => {
    updatePermissions({ classes_ids: [] });
  };

  const checkClassesPermissions = (id: TariffClass['id']): boolean => {
    return user?.permissions?.classes_ids?.includes(id) || false;
  };

  const addClassesPermissions = (id): void => {
    const classes_ids: TariffClass['id'][] = [...(user?.permissions?.classes_ids || []), id];
    const manage_all_classes = ordersClassesStore?.list?.every((cls) => classes_ids.includes(cls?.id));
    updatePermissions({
      classes_ids,
      manage_all_classes,
    });
  };

  const removeClassesPermissions = (id): void => {
    updatePermissions({
      classes_ids: user?.permissions?.classes_ids?.filter((rid) => rid !== id),
      manage_all_classes: 0,
    });
  };

  const onChangeAll = (value: 0 | 1): void => {
    let classes_ids: TariffClass['id'][] = [];
    if (value) {
      classes_ids = ordersClassesStore?.list?.map((cls) => cls?.id);
    }
    updatePermissions({
      classes_ids,
      manage_all_classes: value,
    });
  };

  return (
    <ModalBase onClose={onClose} title={t('dialog.select_class')} visible={isOpen}>
      <main>
        <div className="container1">
          <div className="area overflow-hidden">
            <SearchBar containerClass="" store={ordersClassesStore} placeholder={t('search_placeholder')} />
          </div>
          {ordersClassesStore.isFetchingListInProgress || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area overflow-hidden">
              <div className="overflow-x-auto flex">
                <Table
                  stickyHeader
                  tableClass="table w-full"
                  colClasses={['w-full']}
                  columnsKeys={['title']}
                  data={ordersClassesStore?.list || []}
                  translateFunc={(key: string) => t(`class.${key}`)}
                  customTdInnerRenderer={{
                    title: (record: TariffClass) => (
                      <Checkbox
                        checked={checkClassesPermissions(record.id)}
                        label={record?.name ? tariffT(record?.name) : record?.title}
                        containerClass="flex items-center"
                        labelClass="w-full"
                        onChange={({ target }) =>
                          +target.checked ? addClassesPermissions(record.id) : removeClassesPermissions(record.id)
                        }
                      />
                    ),
                  }}
                  customThInnerRenderer={{
                    title: () => (
                      <Checkbox
                        checked={!!user?.permissions?.manage_all_classes}
                        label={t(`class.all`)}
                        containerClass="flex items-center"
                        labelClass="w-full"
                        onChange={({ target }) => (+target.checked ? onChangeAll(1) : onChangeAll(0))}
                      />
                    ),
                  }}
                >
                  <div className="area-inner">
                    <Pagination compact store={ordersClassesStore} />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
      </main>
      <div className="flex justify-end space-x-5">
        <button
          className="btn btn-light"
          style={{ marginLeft: 20, position: 'fixed', left: 0 }}
          type="button"
          onClick={resetClassesPermissions}
        >
          {t('dialog.reset')}
        </button>
        <button className="btn btn-light" type="button" onClick={onClose}>
          {t('dialog.close')}
        </button>
      </div>
    </ModalBase>
  );
});

export default Dialog;
