import phoneData from './intl-phones.json';
import { PhoneNumberFormat, PhoneNumberUtil, RegionCode } from 'google-libphonenumber';
import { Iso3166Alpha2Code } from 'iso-3166-ts';

const phoneUtil = PhoneNumberUtil.getInstance();

export const placeholders = phoneData['phone-masks'];
export const countryTranslation = phoneData.translations;

export const getCountryCca2 = (phone: string): Iso3166Alpha2Code => {
  const _phone = '+' + (phone || '').replace(/[^0-9]/gi, '');
  try {
    const numberProto = phoneUtil.parse(_phone, '');
    return (phoneUtil.getRegionCodeForNumber(numberProto) || 'RU') as Iso3166Alpha2Code;
  } catch (err) {
    console.log({ err });
  }
  return 'RU';
};

export const formatPerformerPhone = (performerPhone: string = ''): string => {
  const commaInd = performerPhone.indexOf(',');
  if (commaInd > 0) {
    const phone = performerPhone.substring(0, commaInd);
    const added_number = performerPhone.substring(commaInd, performerPhone.length);
    return `${formatPhone(phone)}${added_number}`;
  }
  return formatPhone(performerPhone);
};

export const formatPhone = (phone: string, isInternational: boolean = true): string => {
  const _phone = '+' + (phone || '').replace(/[^0-9]/gi, '');
  if (!_phone) return '';
  if (isInternational) {
    let regionCode: undefined | RegionCode;
    try {
      const numberProto = phoneUtil.parse(_phone, '');
      regionCode = phoneUtil.getRegionCodeForNumber(numberProto);
    } catch (err) {
      console.log(err);
    }

    if (regionCode) {
      try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(_phone, regionCode);
        return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
      } catch (e) {
        return _phone;
      }
    }
    return _phone;
  } else {
    const [number, additionalNum] = phone?.replace(/,,/g, ',').split(',');
    const phoneNum: string = number?.replace(/\D/g, '');
    if (!phoneNum) return '';
    return `+${phoneNum?.slice(0, 1)} (${phoneNum?.slice(1, 4)}) ${phoneNum?.slice(4, 7)}-${phoneNum?.slice(7, 9)}-${phoneNum?.slice(9, 11)}${additionalNum ? `, ${additionalNum}` : ''}`;
  }
};
