import { omit } from 'lodash';
import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import { RootStoreInterface } from '../../interfaces';
import BaseStore from '../BaseStore';
import UsersApi from './api';
import { Me, ProfileRole } from 'types/types';
import Storage from 'utils/storage';

const passKeys = ['current_password', 'new_password', 'new_password2'];

class UsersStore extends BaseStore {
  api: UsersApi;

  @observable me: Me;
  @observable userFetchingError = '';
  @observable isUserInfoFetchingInProgress = false;
  @observable isUserInfoFetched = false;
  @observable isUserSavingInProgress: boolean = false;
  @observable userSavingError?: string;

  @observable profilesList: ProfileRole[] = [];
  @observable isFetchingProfilesList: boolean = false;
  @observable profilesListFetchingError: any;
  @observable activeProfileId: ProfileRole['id'];

  @observable shouldSelectProfile: boolean = false;
  @observable isSelectProfileInProggress: boolean = false;
  @observable selectingProfileId: ProfileRole['id'];
  @observable selectProfileError: any = false;

  @observable isFetchingSettingsInProgress: boolean = false;
  @observable settings?: any;
  @observable isSavingSettingsInProgress: boolean = false;

  @observable isSendingFeedback?: boolean;
  @observable sentFeedback?: boolean;
  @observable sendFeedbackError?: string;

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Users']);
    this.api = new UsersApi(rootStore.specifications['Users'].url);
    this.setApiClient(this.api);
    this.init();

    this.me = {};
    makeObservable(this);
  }

  @action
  async init() {
    this.activeProfileId = await Storage.getItem('activeProfileId');
  }

  @action
  async fetchSettings(): Promise<Me['settings']> {
    runInAction(() => {
      this.fetchingSettingsError = '';
      this.isFetchingSettingsInProgress = false;
    });

    try {
      const data = await this.api.getSettings();
      runInAction(() => {
        this.settings = this.recordHandlers({ ...data });
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.fetchingSettingsError = err as string;
        this.isFetchingSettingInProgress = false;
      });
    }
  }

  async saveSettings(params: any): Promise<void> {
    runInAction(() => {
      this.savingSettingsError = '';
      this.isSavingSettingsInProgress = false;
    });

    try {
      console.log('saveSettings params ->', params);
      if (!params || typeof params !== 'object') throw Error('invalidSettings');
      if (passKeys.every((key: string) => params[key])) {
        await this.api.savePassword(passKeys.reduce((acc, key) => ({ ...acc, [key]: params[key] }), {}));
      }
      if (params?.lang_id) {
        await this.saveUserInfo({ lang_id: params?.lang_id });
      }
      const data = await this.api.saveSettings(omit(params || {}, passKeys));
      console.log('saveSettings data -->', data);
      // runInAction(() => {
      //   this.settings = this.recordHandlers({ ...data });
      // });
      return data;
    } catch (err) {
      console.log('saveSettings err -->', err);
      this.captureError(err);
      runInAction(() => {
        this.savingSettingsError = (err as any)?.message || err;
        this.isSavingSettingInProgress = false;
      });
    }
  }

  @action
  async fetchProfiles(): Promise<void> {
    runInAction(() => {
      this.profilesList = [];
      this.isFetchingProfilesList = true;
      this.profilesListFetchingError = '';
    });
    try {
      const list = await this.api.getRoles();
      runInAction(() => {
        this.profilesList = list;
      });
    } catch (err) {
      runInAction(() => {
        this.profilesListFetchingError = err;
      });
      this.captureError(err);
      console.log(err);
    }
    runInAction(() => {
      this.isFetchingProfilesList = false;
    });
  }

  async getRoleToken({ id, name }: Partial<ProfileRole>) {
    let response;

    try {
      response = await this.api.getRoleToken({ id, name });
    } catch (err) {
      this.captureError(err);
      console.log(err);
    }
    return response;
  }

  @action
  async seletcProfile(role: ProfileRole) {
    if (role?.id == this.activeProfileId) return;
    runInAction(() => {
      this.selectingProfileId = role?.id;
      this.isSelectProfileInProggress = true;
    });
    try {
      const { access_token } = await this.rootStore.modulesStore.usersStore.getRoleToken(role);
      await Storage.setItem('access_token', access_token);
      this.updateWithStorage('activeProfileId', role?.id);
      window.location.reload();
    } catch (err) {
      runInAction(() => {
        this.selectProfileError = err;
        this.isSelectProfileInProggress = false;
      });
    }
    runInAction(() => {
      this.selectingProfileId = undefined;
    });
  }

  @action
  async fetchUserInfo(): Promise<void> {
    console.log('USER', 'Fetch data');

    runInAction(() => {
      this.isUserInfoFetched = false;
      this.isUserInfoFetchingInProgress = true;
    });

    try {
      const data = await this.api.getMe();
      runInAction(() => {
        this.me = { ...data };
        console.log('USER', 'Info fetched');
        this.isUserInfoFetched = true;
        this.isUserInfoFetchingInProgress = false;

        if (
          this.rootStore &&
          this.rootStore.modulesStore &&
          this.rootStore.modulesStore.webSocketStore &&
          this.rootStore.modulesStore.webSocketStore.subscribe
        )
          this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, 'record', {
            id: data.id,
          });
      });
    } catch (err) {
      console.error('USER', 'Fetching problem', err);
      this.captureError(err);
      runInAction(() => {
        this.userFetchingError = err as string;
        this.isUserInfoFetched = true;
        this.isUserInfoFetchingInProgress = false;
        console.error('Problem fetching user info: ' + err);
      });
    }
  }

  @action
  async sendFeedback(text): Promise<void> {
    const { detail } = this.me || {};
    const customer_id = this.isCustomer ? detail?.id : detail?.customer_id;
    runInAction(() => {
      this.isSendingFeedback = true;
      this.sentFeedback = false;
      this.sendFeedbackError = '';
    });

    try {
      const data = await this.api.sendFeedback({ text, customer_id });
      runInAction(() => {
        this.isSendingFeedback = false;
        this.sentFeedback = true;
        setTimeout(() => {
          runInAction(() => {
            this.sentFeedback = false;
          });
        }, 5000);
      });
    } catch (err) {
      runInAction(() => {
        this.isSendingFeedback = false;
        this.sentFeedback = true;
        this.sendFeedbackError = err as string;
        setTimeout(() => {
          runInAction(() => {
            this.sentFeedback = false;
          });
        }, 5000);
      });
    }
  }

  async saveUserInfo(params: any): Promise<void> {
    runInAction(() => {
      this.isUserSavingInProgress = true;
      this.userSavingError = undefined;
    });

    try {
      const response = await this.api.putMe(params);
      runInAction(() => {
        this.me = { ...response };
        this.isUserSavingInProgress = false;
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.isUserSavingInProgress = false;
        this.userSavingError = err as string;
      });
    }
  }

  async recordUpdated(record: any) {
    if (this.me.id && record.id === this.me.id) {
      this.me = { ...record };
    }
  }

  @action setLocale(locale: string, lang_id: string) {
    runInAction(() => {
      if (!this.me?.lang) return;
      this.me.lang.locale = locale;
    });
    this.saveUserInfo({ lang_id });
  }

  @computed
  get customer() {
    return this.me?.detail?.customer;
  }

  @computed
  get companyName(): string | undefined {
    return this.isCustomer
      ? this.me?.name
      : this.isEmployee || this.isCoordinator
        ? this.me?.detail?.customer?.name
        : undefined;
  }
  @computed
  get companyAvatar(): string | undefined {
    const isVip = this.rootStore?.modulesStore?.ordersStore?.isVip;
    return this.me?.detail?.customer?.avatar || `${process.env.PUBLIC_URL}/i/${isVip ? 'logoVip' : 'logo'}.svg`;
  }

  @computed
  get locale(): string {
    return this.me && this.me.lang && this.me.lang.locale ? this.me.lang.locale : 'ru_RU';
  }
  @computed
  get role(): string | undefined {
    return this.me && this.me.role && this.me.role.name ? this.me.role.name : undefined;
  }
  @computed
  get isPromo() {
    return this.me?.detail?.customer?.is_promo;
  }
  @computed
  get isDepartmentsV2(): boolean {
    return !!this.me?.detail?.customer?.is_departments_v2;
  }
  @computed
  get isEmployeeCodeRequired(): boolean {
    return !!this.me?.detail?.customer?.is_employee_code_required;
  }
  @computed
  get isEmployeeDepartmentRequired(): boolean {
    return !!this.me?.detail?.customer?.is_employee_department_required;
  }
  @computed
  get isEmployeeCoordinatorRequired(): boolean {
    return !!this.me?.detail?.customer?.is_employee_coordinator_required;
  }
  @computed
  get isPrime() {
    return this.me?.detail?.is_vip;
  }
  @computed
  get isAdmin(): boolean {
    return this.role === 'admin';
  }
  @computed
  get isOperator(): boolean {
    return this.role === 'operator';
  }
  @computed
  get isEmployee(): boolean {
    return this.role === 'employee';
  }
  @computed
  get isCustomer(): boolean {
    return this.role === 'customer';
  }
  @computed
  get isCoordinator(): boolean {
    return this.role === 'coordinator';
  }
  @computed
  get isCostWithVat(): boolean {
    return !!this.me?.detail?.is_estimate_cost_with_vat;
  }

  @computed
  get currentProfile(): ProfileRole {
    const { me } = this;
    const { detail } = me || {};
    return {
      label: detail?.name,
      limits: {
        limit_month_amount: detail?.limit_month_amount,
        limit_group_month_amount: detail?.limit_group_month_amount,
        limit_month_amount_used: detail?.limit_month_amount_used,
        limit_group_month_amount_used: detail?.limit_group_month_amount_used,
      },
      customer: {
        avatar: detail?.customer?.avatar,
        name: detail?.customer?.name,
      },
    };
  }
}

export default UsersStore;
