export default {
  title: 'Coordinators',
  action: {
    add: 'Add Coordinator',
    edit: 'Edit Coordinator',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full Name',
    email: 'E-mail',
  },
  dialog: {
    full_name: 'Full Name',
    full_name_placeholder: 'Enter Coordinator Full Name',
    email_placeholder: 'Enter E-mail',
    email: 'E-mail',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    permissions: 'Permissions',
    create_policy_groups: 'Create Travel Policy Groups',
    create_cost_centers: 'Create Cost Centers',
    create_favourites: 'Create Favorites',
    create_employees: 'Create Employees',
    manage_can_order: 'Grant Employees the Right to Self-Order',
    manage_can_order_others: 'Grant Employees the Right to Order for Others',
    view_analytics: 'View Analytics',
    create_coordinators: 'Create Coordinators',
    manage_promocodes: 'Manage Promo Codes',
    select_employee: 'Select Employees',
    close: 'Close',
    reset: 'Clear',
    select: 'Select',

    employees_policy: 'Manage Employees and Travel Policy Groups',
    select_employees: 'No Employees Selected',
    manage_all_employees: 'All Employees',
    selected_employees: 'Available Employees',
    selected_classes: 'Available Classes',
    select_classes: 'No Classes Selected',
    manage_all_classes: 'All Classes',
    select_class: 'Select Available Classes',
    select_group: 'Select Available Groups',

    select_roles: 'No Groups Selected',
    manage_all_roles: 'All Groups',
    selected_roles: 'Available Groups',
    search_placeholder: 'Search',
  },
  class: {
    all: 'All Classes',
  },
  group: {
    search_placeholder: 'Find Groups',
    all: 'All Groups',
    default: 'Default',
    add_user: 'Select Employees',
  },
  employees: {
    search_placeholder: 'Find Employees',
    all: 'All Employees',
  },
};
