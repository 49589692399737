import BaseCRUD from 'stores/BaseCRUD';
import WebApiClient from '../WebApi';
import { Limit } from '../../types/types';

export class LimitsApi extends BaseCRUD {
  async createLimit(id, data: Limit) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/employee/${id}`,
      data,
    });
  }
  async getLimit(id) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/employee/${id}`,
    });
  }
}

export default LimitsApi;
