import { observer } from 'mobx-react-lite';
import React from 'react';

interface TabSwitcherProps {
  activeTab?: string;
  setActiveTab: (s: string) => void;
  tabs: { name: string; value: string }[];
}

export const TabSwitcher = observer(({ activeTab, setActiveTab, tabs }: TabSwitcherProps) => {
  return (
    <div className="flex w-full mb-5 h-[40px] rounded-[50px] bg-graylight relative">
      <div
        className={`absolute top-0 bottom-0 w-half bg-active rounded-[50px] transition-all duration-300 ${
          activeTab === 'data' ? 'left-0' : 'left-1/2'
        }`}
      ></div>
      {tabs.map((b) => (
        <button
          key={`switcher-button-${b.value}`}
          type="button"
          className={`flex-1 py-2 text-center relative z-10 text-black ${
            activeTab === b.value ? 'font-medium' : ''
          } transition-all duration-300`}
          onClick={() => setActiveTab(b.value)}
        >
          {b.name}
        </button>
      ))}
    </div>
  );
});
