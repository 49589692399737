export default {
  title: 'Employees',
  action: {
    add: 'Add employee',
    edit: 'Editing',
    import: 'Import employees',
    export: 'Export',
    exporting: 'Exporting',
    rides: 'Rides',
    resend_sms: 'Resend SMS',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full name',
    phone: 'Phone',
    code: 'Employee code',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
    branch: 'Branch',
    role: 'Role',
    limit: 'Limit',
    remainder: 'Remainder',
    order_right: 'Rights to order',
    status: 'Status',
    app_last_visit: 'Last login time',
  },
  trow: {
    can_order: 'Corp. trips for himself',
    can_order_others: 'Corp. trips to others',
    limit_personal: 'personal',
    limit_group: 'for group',
  },
  import: {
    imported_number: 'Records imported',
    failed_number: 'Records skipped',
    errors: 'Import errors',
    line: 'Line',
    import_process: 'Import',
    from: 'from',
    close: 'Close',
    dropzone_text: 'Click or drag a file into this area',
    import_example: 'Import file example',
    import_file_name: 'import',
    end: 'The import has completed',
    success: 'Successfully imported',
    lines: 'lines',
    lines_s1: 'line',
    lines_s2: 'lines',
  },
  filter: {
    actions: {
      apply: 'Apply',
    },
    role: 'Role',
    title: 'Filters',
  },
  dialog: {
    full_name: 'Full name',
    phone: 'Phone',
    email: 'E-mail',
    employee_code: 'Employee code',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
    order_right: 'Rights to order',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    policy_group: 'Travel policy group',
    passenger: 'Passenger',
    coordinator: 'Personal coordinator',
    not_selected: 'Not selected',
    can_order: 'Order for himself',
    can_order_text: 'Can order corporate trips for himself',
    can_order_others: 'Order for others',
    can_order_others_text: 'Can order trips for other employees',
    active: 'Activity',
    active_text: 'Active',
    is_vip: 'Prime-theme',
    is_vip_text: 'Prime',
    send_welcome_sms: 'Send invitation by SMS',
    limit_month_amount: 'Limit, rub. with VAT',
    without_limit: 'Without limita',
    active_from_time: 'Active from',
    active_to_time: 'Active to',
    time: 'Time',
    tabs: {
      limits: 'Limits',
      data: 'Personal data',
    },
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
  },
  limits: {
    cost_restriction: 'Cost restriction',
    time_restrictions: 'Time restriction',
    class_restrictions: 'Class restriction',
    chosen: 'Chosen',
    restrictions: 'restrictions',
    restrictions_s1: 'restriction',
    restrictions_s2: 'restrictions',
    cost: 'Limit, with VAT',
    l_type: 'Limit type',
    period: 'Period',
    total_cost_inc_tax: 'Max cost',
    max_order_count: 'Max order count',
    cost_type: {
      month: 'Month',
      day: 'Day',
      week: 'Week',
    },
    interval: 'Interval',
    time_limit_type: 'Type',
    time_limit_period: 'Period',
    time_limit_from: 'Active from',
    time_limit_to: 'Active to',
    add: '+ Limit',
    time_limit: 'Interval',
    time_interval_info: 'Dates have higher priority',
    classes_all: 'Select all',
    type: {
      interval: 'interval',
      weekdays: 'Weekdays',
      weekends: 'Weekends',
      days: 'Days',
    },
    week: {
      mo: 'Mo',
      tu: 'Tu',
      we: 'We',
      th: 'Th',
      fr: 'Fr',
      sa: 'Sa',
      su: 'Su',
    },
  },
};
