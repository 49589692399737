import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import ModalSelectStore from 'components/ModalSelectStore';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import FASpinner from 'components/FASpinner';
import { observer } from 'mobx-react-lite';
import { handleEmployeeName } from 'utils/lib/regExp/handleEmployeeName';
import { Checkbox } from 'components/Inputs/Checkbox';
import { Coordinator } from 'modules/Coordinators';
import { isEmailValid } from 'utils/lib/regExp/isEmailValid';
import { DateTimeField } from 'components/Inputs/DateTimeField';
import { ACTIVE_DATE } from 'constants/formats';
import PhoneInput from '../../components/Inputs/PhoneInput';
import { Dialog as DepartmentDialog } from './departmentDialog';
import { ReactComponent as AddIcon } from '../../assets/svg/add.svg';
import { ReactComponent as EditIcon } from '../../assets/svg/edit.svg';
import { Employee } from './dialog';

interface DataProps {
  user?: Employee;
  visible?: boolean;
  onCancel: any;
  onClose: any;
}
interface Validation {
  phone?: string;
  email?: string;
  name?: string;
  code?: string;
  coordinator_id?: string;
  department_id?: string;
  department_name?: string;
  department_code?: string;
}

const defaultEmployeeV1: Partial<Employee> = {
  department_name: '',
  department_code: '',
};
const defaultEmployeeV2 = {
  department_id: '',
};
const getDefaultEmployee: (data: Partial<Employee>) => Employee = (data) => ({
  phone: '',
  email: '',
  name: '',
  role: undefined,
  can_order: 1,
  role_id: 0,
  can_order_others: 0,
  active: 1,
  ...data,
  code: '',
  send_welcome_sms: 1,
  coordinator_id: undefined,
});

const not_selected = 'not_selected';

const INPUT_BASE_CLASS = 'bg-graylight bg-opacity-94 h-12';

interface DepartmentDialog {
  id: string | number | null;
  isOpen: boolean;
}

export const DataTab = observer(({ user, visible, onCancel, onClose }: DataProps): JSX.Element | null => {
  const { usersStore, employeesStore } = useStores();
  const isPersonalManagerPolicy = usersStore?.customer?.is_personal_manager_policy;

  const [departmentDialog, setDepartmentDialog] = useState<DepartmentDialog>({
    id: null,
    isOpen: false,
  });

  const closeDialog = (id: number | string = '') => {
    if (id) {
      handleChangeByField({ department_id: String(id) });
    }
    setDepartmentDialog({
      id: null,
      isOpen: false,
    });
  };

  const today = new Date();
  today.setMinutes(0, 0, 0);

  const getEmploeeData = (): Employee => {
    return {
      phone: user?.phone || '',
      email: user?.email || '',
      name: user?.name || '',
      role: user?.role || undefined,
      can_order: user
        ? user?.can_order == 0
          ? 0
          : 1
        : usersStore?.me?.detail?.customer?.is_employee_can_order || usersStore?.me?.detail?.is_employee_can_order,
      can_order_others: user
        ? user?.can_order_others || 0
        : usersStore?.me?.detail?.customer?.is_employee_can_order_others ||
          usersStore?.me?.detail?.is_employee_can_order_others,
      role_id: user?.role_id || undefined,
      coordinator_id: user ? user?.coordinator_id : usersStore?.isCoordinator ? usersStore?.me?.detail?.id : undefined,
      active: user?.active == 0 ? 0 : 1,
      is_vip: user?.is_vip || 0,
      ...(usersStore.isDepartmentsV2
        ? {
            department_id: user?.department_id || user?.department?.id || '',
          }
        : {
            department_name: user?.department_name || '',
            department_code: user?.department_code || '',
          }),
      code: user?.code || '',
      send_welcome_sms: user ? (user?.send_welcome_sms == 1 ? 1 : 0) : 1,
      active_from_time: user?.active_from_time,
      active_to_time: user?.active_to_time,
    };
  };
  const [employee, setEmployee] = useState<Employee>(getEmploeeData());
  const [errors, setErrors] = useState<Validation>({});
  const { t } = useTranslation(['employees', 'validation']);
  const isOnlyOneRoleSet = useRef<boolean>(false);

  const defaultEmployee = getDefaultEmployee(usersStore?.isDepartmentsV2 ? defaultEmployeeV2 : defaultEmployeeV1);

  useEffect(() => {
    setErrors({});
    resetEmployee();
    if (visible) {
      const eml = getEmploeeData();
      setEmployee(eml);
    }
    isOnlyOneRoleSet.current = false;
    document.body.style.overflow = visible ? 'hidden' : 'unset';
    return () => {
      setErrors({});
      resetEmployee();
    };
  }, []);

  const handleChangeByField = (data: Partial<Employee>): void => {
    let newData = { ...data };

    setEmployee((prevEmpl) => ({
      ...prevEmpl,
      ...newData,
    }));
  };

  const cancel = (): void => {
    setEmployee({
      ...defaultEmployee,
      can_order:
        usersStore?.me?.detail?.customer?.is_employee_can_order || usersStore?.me?.detail?.is_employee_can_order,
      can_order_others:
        usersStore?.me?.detail?.customer?.is_employee_can_order_others ||
        usersStore?.me?.detail?.is_employee_can_order_others,
    });
    if (onCancel) {
      onCancel();
    }
  };

  const addEmployee = async (e: any): Promise<void> => {
    e.preventDefault();
    const { phone, email, name, coordinator_id, code, department_id, department_code, department_name } =
      employee || {};
    const empl: Employee = { ...employee };
    const required: string = t('error.required', { ns: 'validation' });
    const error: Validation = {};
    if (!phone) {
      error.phone = required;
    } else if (phone.replace(/\D/g, '').length < 11) {
      error.phone = t('error.phone', { ns: 'validation' });
    }
    if (email && !isEmailValid(email)) {
      error.email = t('error.email', { ns: 'validation' });
    }
    if (!name) {
      error.name = required;
    }
    if (usersStore?.isEmployeeCodeRequired && !code) {
      error.code = required;
    }
    if (typeof coordinator_id === 'undefined' && usersStore?.isEmployeeCoordinatorRequired) {
      error.coordinator_id = required;
    }
    if (usersStore?.isEmployeeDepartmentRequired) {
      if (usersStore?.isDepartmentsV2 && !department_id) {
        error.department_id = required;
      } else if (!usersStore?.isDepartmentsV2) {
        if (!department_code) {
          error.department_code = required;
        }
        if (!department_name) {
          error.department_name = required;
        }
      }
    }
    if (!Object.keys(error).length) {
      // TODO role throws an error on save
      delete empl.role;

      if (usersStore?.isCoordinator && !usersStore?.me?.detail?.manage_can_order) {
        delete empl.can_order;
      }

      if (usersStore?.isCoordinator && !usersStore?.me?.detail?.manage_can_order_others) {
        delete empl.can_order_others;
      }
      const data: Employee = {
        ...empl,
        email: email.trim(),
        name: name.trim(),
        phone: phone.replace(/\D/g, ''),
        code: employee.code?.trim(),
        ...(usersStore.isDepartmentsV2
          ? {
              department_id: employee.department?.id || employee.department_id || null,
            }
          : {
              department_name: employee?.department_name || '',
              department_code: employee?.department_code || '',
            }),
      };
      const filteredData = Object.entries(data).reduce(
        (acc: any, [key, val]: any) => (val === '' && !(user && user[key]) ? acc : { ...acc, [key]: val }),
        {},
      );
      if (user) {
        await user.update(filteredData);
      } else {
        await employeesStore.addRecord(filteredData);
      }
      await employeesStore.fetchList(
        false,
        employeesStore.filter,
        employeesStore.orderBy || 'id desc',
        employeesStore.page || 0,
        employeesStore?.limit || 10,
        true,
        false,
      );
      if (employeesStore.addingError) return;
      resetEmployee();
      onClose();
      setErrors({});
    } else {
      setErrors(error);
    }
  };

  const resetEmployee = (): void => {
    setEmployee({
      ...defaultEmployee,
      active: 0,
      can_order:
        usersStore?.me?.detail?.customer?.is_employee_can_order || usersStore?.me?.detail?.is_employee_can_order,
      can_order_others:
        usersStore?.me?.detail?.customer?.is_employee_can_order_others ||
        usersStore?.me?.detail?.is_employee_can_order_others,
    });
  };

  const onFocus = (field: 'phone' | 'email' | 'name'): void => {
    if (errors[field]) {
      const err: Validation = { ...errors };
      delete err[field];
      setErrors(err);
    }
  };

  const roleIdFilter = useMemo(() => {
    const filter: any = employee?.role_id ? { id: employee?.role_id } : {};
    // If isPersonalManagerPolicy then get only coordinartor's groups
    if (isPersonalManagerPolicy && employee.coordinator_id) {
      filter.coordinator_id = employee.coordinator_id;
    }
    return filter;
  }, [employee?.role_id, isPersonalManagerPolicy, employee.coordinator_id]);

  const coordinatorIdFilter = useMemo(() => {
    return {
      ...(employee?.coordinator_id ? { id: employee?.coordinator_id } : {}),
      is_disabled: 0,
    };
  }, [employee?.coordinator_id]);

  const onCoordinatorLoaded = useCallback(
    (data: Coordinator[]): void => {
      if (!data?.length && (usersStore?.isAdmin || usersStore?.isCustomer)) {
        handleChangeByField({ coordinator_id: null });
      }
    },
    [handleChangeByField],
  );

  return (
    <>
      <fieldset className="mb-1">
        <div className="mb-5">
          <label className="form-label form-label-required" htmlFor="firstname">
            {t('dialog.full_name')}
          </label>
          <input
            className={`form-control py-3 ${INPUT_BASE_CLASS}`}
            type="text"
            onChange={(e) => handleChangeByField({ name: handleEmployeeName(e.target.value, true) })}
            id="firstname"
            placeholder={t('dialog.full_name')}
            value={employee.name}
            onFocus={() => onFocus('name')}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
        <div className="mb-5 lg:flex lg:gap-x-5">
          <div className="flex-1 mb-5 lg:mb-0">
            <label className="form-label form-label-required" htmlFor="phone">
              {t('dialog.phone')}
            </label>
            <PhoneInput
              onChange={(value) => {
                handleChangeByField({ phone: value });
              }}
              value={employee.phone || ''}
              defaultValue={employee.phone || ''}
              id="phone"
              onFocus={() => onFocus('phone')}
              disabled={!!user}
              className={`form-control py-3 bg-graylight ${
                !!user && !!employee?.phone ? 'form-control-disabled' : 'bg-opacity-94'
              } h-12 ${errors.phone ? 'validate-error' : ''}`}
            />
            {errors.phone && <span className="error">{errors.phone}</span>}
          </div>
        </div>
        <div className="mb-5">
          <div className="flex-1 mb-5 lg:mb-0">
            <label className="form-label" /*for="email"*/>{t('dialog.email')}</label>
            <input
              className={`form-control py-3 ${INPUT_BASE_CLASS} ${errors.email ? 'validate-error' : ''}`}
              type="text"
              onFocus={() => onFocus('email')}
              id="email"
              placeholder="ivanov@company.ru"
              value={employee.email}
              onChange={(e) => handleChangeByField({ email: e.target.value })}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>
        <div className="mb-5">
          <label className={`form-label ${usersStore?.isEmployeeCoordinatorRequired ? 'form-label-required' : ''}`}>
            {t('dialog.coordinator')}
          </label>
          <div className="select">
            <ModalSelectStore
              listProps={{
                'aria-labelledby': 'role-label',
                'aria-activedescendant': 'role-option-0',
              }}
              {...(usersStore?.isAdmin ||
              usersStore?.isCustomer ||
              (user && !user?.coordinator_id && usersStore?.isCoordinator)
                ? { defaultOptions: [{ id: not_selected, name: t('dialog.not_selected') }] }
                : {})}
              onInitialDataLoaded={onCoordinatorLoaded}
              store={employeesStore}
              fieldName={'coordinator_id'}
              disabled={usersStore?.isCoordinator && ((user && !!user?.coordinator_id) || !user)}
              filter={coordinatorIdFilter}
              omitValuesOnFocus={usersStore?.isCoordinator ? [] : ['id']}
              placeholder={t('dialog.coordinator')}
              value={employee?.coordinator_id === null ? not_selected : employee?.coordinator_id}
              // Reset role_id when coordinator_id is changed (role_id: undefined)
              onChange={(id: Coordinator['id']) =>
                handleChangeByField({
                  coordinator_id: id ? (id === not_selected ? null : id) : defaultEmployee.coordinator_id,
                  ...(isOnlyOneRoleSet.current ? {} : { role_id: undefined }),
                })
              }
            />
            {errors.coordinator_id && <span className="error">{errors.coordinator_id}</span>}
          </div>
        </div>
        <div className="mb-5">
          <label className="form-label form-label-required" /*for="role"*/>{t('dialog.policy_group')}</label>
          <div className="select">
            <ModalSelectStore
              listProps={{
                'aria-labelledby': 'role-label',
                'aria-activedescendant': 'role-option-0',
              }}
              store={employeesStore}
              fieldName={'role_id'}
              disabled={isPersonalManagerPolicy && typeof employee.coordinator_id === 'undefined'}
              filter={roleIdFilter}
              omitValuesOnFocus={['id']}
              placeholder={t('dialog.passenger')}
              value={employee?.role_id}
              onSetDefaultValueIfLengthEq1={() => (isOnlyOneRoleSet.current = true)}
              onChange={(id: number) => handleChangeByField({ role_id: id ? id : defaultEmployee.role_id })}
              isSetDefaultValueIfLengthEq1
            />
          </div>
        </div>
        <div className="mb-5">
          <label className={`form-label ${usersStore?.isEmployeeCodeRequired ? 'form-label-required' : ''}`}>
            {t('dialog.employee_code')}
          </label>
          <input
            className={`form-control py-3 ${INPUT_BASE_CLASS}`}
            type="text"
            onChange={(e) => handleChangeByField({ code: e.target.value })}
            id="employee_code"
            placeholder={t('dialog.employee_code')}
            value={employee.code}
          />
          {errors.code && <span className="error">{errors.code}</span>}
        </div>
        {usersStore?.isDepartmentsV2 ? (
          <div className="mb-5">
            <label className={`form-label ${usersStore?.isEmployeeDepartmentRequired ? 'form-label-required' : ''}`}>
              {t('dialog.department_id')}
            </label>
            <div className="flex">
              <ModalSelectStore
                listProps={{
                  'aria-labelledby': 'role-label',
                  'aria-activedescendant': 'role-option-0',
                }}
                wrapperClass="flex-1"
                store={employeesStore}
                fieldName={'department_id'}
                filter={{}}
                omitValuesOnFocus={usersStore?.isCoordinator ? [] : ['id']}
                placeholder={t('dialog.department_id')}
                onChange={(id) => handleChangeByField({ department_id: id === not_selected ? null : id })}
                nested
                value={employee?.department_id === null ? '' : employee?.department_id}
              />
              {errors.department_id && <span className="error">{errors.department_id}</span>}
              <button
                className="btn btn-blue px-1.5 w-10 flex items-center md:px-4 md:w-auto ml-2 h-12"
                type="button"
                onClick={() => {
                  setDepartmentDialog({ id: employee?.department_id || '', isOpen: true });
                }}
              >
                {employee?.department_id ? <EditIcon /> : <AddIcon />}
              </button>
            </div>
            {errors.department_id && <span className="error">{errors.department_id}</span>}
          </div>
        ) : (
          <>
            <div className="mb-5">
              <label className={`form-label ${usersStore?.isEmployeeDepartmentRequired ? 'form-label-required' : ''}`}>
                {t('dialog.department_code')}
              </label>
              <input
                className={`form-control py-3 ${INPUT_BASE_CLASS}`}
                type="text"
                onChange={(e) => handleChangeByField({ department_code: e.target.value })}
                id="department_code"
                placeholder={t('dialog.department_code')}
                value={employee.department_code || ''}
              />
              {errors.department_code && <span className="error">{errors.department_code}</span>}
            </div>
            <div className="mb-5">
              <label className={`form-label ${usersStore?.isEmployeeDepartmentRequired ? 'form-label-required' : ''}`}>
                {t('dialog.department_name')}
              </label>
              <input
                className={`form-control py-3 ${INPUT_BASE_CLASS}`}
                type="text"
                onChange={(e) => handleChangeByField({ department_name: e.target.value })}
                id="department_name"
                placeholder={t('dialog.department_name')}
                value={employee.department_name || ''}
              />
              {errors.department_name && <span className="error">{errors.department_name}</span>}
            </div>
          </>
        )}

        <DateTimeField
          fieldProps={{
            title: t('dialog.active_from_time'),
            className: 'mb-5',
          }}
          showTimeSelect
          className={`${INPUT_BASE_CLASS}`}
          onChange={(v) => handleChangeByField({ active_from_time: v ? v.getTime() / 1000 : null })}
          dateFormat={ACTIVE_DATE}
          minDate={today}
          maxDate={(employee.active_to_time && new Date(employee.active_to_time * 1000)) || undefined}
          selected={employee.active_from_time && new Date(employee.active_from_time * 1000)}
          placeholderText={t('dialog.active_from_time')}
        />
        <DateTimeField
          fieldProps={{
            title: t('dialog.active_to_time'),
            className: 'mb-5',
          }}
          showTimeSelect
          className={INPUT_BASE_CLASS}
          onChange={(v) => handleChangeByField({ active_to_time: v ? v.getTime() / 1000 : null })}
          dateFormat={ACTIVE_DATE}
          minDate={(employee.active_from_time && new Date(employee.active_from_time * 1000)) || undefined}
          selected={employee.active_to_time && new Date(employee.active_to_time * 1000)}
          placeholderText={t('dialog.active_to_time')}
        />
      </fieldset>
      <fieldset className="mb-1">
        <h5 className="text-2lg leading-6 text-black mb-4">{t('dialog.order_right')}</h5>
        <h6 className="text-black mb-2.5">{t('dialog.can_order')}</h6>
        <Checkbox
          containerClass="mb-4"
          disabled={usersStore?.isCoordinator && !usersStore?.me?.detail?.manage_can_order}
          onChange={({ target }) => handleChangeByField({ can_order: +target.checked })}
          checked={!!employee.can_order}
          labelClass="text-opacity-40"
          label={t('dialog.can_order_text')}
        />
        <h6 className="text-black mb-2.5">{t('dialog.can_order_others')}</h6>
        <Checkbox
          containerClass="mb-4"
          onChange={({ target }) => handleChangeByField({ can_order_others: +target.checked })}
          checked={!!employee.can_order_others}
          labelClass="text-opacity-40"
          label={t('dialog.can_order_others_text')}
        />
        <h6 className="text-black mb-2.5">{t('dialog.active')}</h6>
        <Checkbox
          containerClass="mb-4"
          onChange={({ target }) => handleChangeByField({ active: +target.checked })}
          checked={!!employee.active}
          label={t('dialog.active_text')}
        />
        {!user && (
          <Checkbox
            containerClass="mb-4"
            onChange={({ target }) => handleChangeByField({ send_welcome_sms: +target.checked })}
            checked={!!employee.send_welcome_sms}
            labelClass="text-opacity-40"
            label={t('dialog.send_welcome_sms')}
          />
        )}
        {(usersStore?.me?.role?.name === 'coordinator' && usersStore?.me?.detail?.customer?.is_vip) ||
        (usersStore?.me?.role?.name === 'customer' && usersStore?.me?.detail?.is_vip) ? (
          <>
            <h6 className="text-black mb-2.5">{t('dialog.is_vip')}</h6>
            <Checkbox
              containerClass="mb-4"
              onChange={({ target }) => handleChangeByField({ is_vip: +target.checked })}
              checked={!!employee.is_vip}
              labelClass="text-opacity-40"
              label={t('dialog.is_vip_text')}
            />
          </>
        ) : null}
      </fieldset>
      <div className="flex justify-end space-x-5">
        <button className="btn btn-light" onClick={cancel} type="button">
          {t('dialog.cancel')}
        </button>
        <button
          className="btn btn-blue"
          type="submit"
          disabled={!employee.name || !employee.phone || !employee.role_id || employeesStore?.isAddingInProgress}
          onClick={addEmployee}
        >
          <FASpinner containerClass="mr-2" show={employeesStore?.isAddingInProgress} />
          {user ? t('dialog.save') : t('dialog.add')}
        </button>
      </div>

      <DepartmentDialog
        department_id={departmentDialog.id}
        visible={departmentDialog.isOpen}
        onCancel={closeDialog}
        onClose={closeDialog}
        store={employeesStore}
        fieldName={'department_id'}
      />
    </>
  );
});
